import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { refreshProductImageUrls } from '../store/actions';
import { makeStyles } from '@material-ui/core/styles';
import { getThumbUrl } from '../store/actions';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { ButtonLink, debug  } from '../utils/utils';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(theme => ({
  container: {
    margin: "8px 0px 8px",
  },
  handle: {
    color: theme.palette.text.secondary,
    marginTop: 0,
  },
  avatar: {
    width: 100,
    height: 100,
  }
}));

function ProductPreview(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');

  useEffect(() => {
    if(props.product && props.product.images && props.product.images.length>0 && !props.product.images[0].thumb) props.refresh(props.product);
  }, []);

  const secondary = (
    <React.Fragment>
      <NumberFormat
        value={props.product.price}
        prefix={props.product.currency}
        displayType={'text'}
        thousandSeparator={true} 
        decimalScale={2}
        fixedDecimalScale={true}
      />
    </React.Fragment>
  );

  let thumbUrl = null;
  let images = props.product ? props.product.images : null;
  images = images.filter(x => !x.deleted);
  if(images && images.length>0) {
    thumbUrl = images[0].thumb || images[0].url;
  }

  return (
    <ListItem button divider disableGutters className={classes.container}
      component={ButtonLink}
      to={`/products/${props.productId}`}
    >
      <ListItemAvatar>
        <Avatar alt={props.product.name} variant="rounded" 
          src={thumbUrl} 
        />
      </ListItemAvatar>
      <ListItemText primary={props.product.name} secondary={secondary}/>

      <ListItemSecondaryAction>
        <IconButton aria-label="edit" 
          component={ButtonLink}
          to={`/shops/${props.product.shopId}/products/${props.productId}/edit`}
        >
          <EditIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

const mapStateToProps = (state, ownProps) => {
  return { };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    refresh: (product) => {
      dispatch(refreshProductImageUrls(product));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPreview);
