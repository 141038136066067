import React from 'react';
import { connect } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import { closeStatusMessage } from '../store/actions';

const useStyles = makeStyles({
  snackbar: { position: "fixed" },
  snackbarContent: {
    width: 360,
  },
});

function StatusMessage(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');

  return (
    <Snackbar
      open={props.openStatusMessage}
      autoHideDuration={4000}
      onClose={props.onClose}
      ContentProps={{
        'aria-describedby': 'snackbar-fab-message-id',
        className: classes.snackbarContent,
      }}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={props.onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      message={t(props.statusMessage)}
      className={classes.snackbar}
    />
  )
}

const mapStateToProps = state => {
  return {
    openStatusMessage: state.openStatusMessage,
    statusMessage: state.statusMessage,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(closeStatusMessage());
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusMessage);

