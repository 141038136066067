import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { fetchPaymentLink, fetchShopById, addToCart, setPage,
  fetchShopOwnerStripeAccountId, fetchStripeSession, 
} from '../store/actions';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ShareIcon from '@material-ui/icons/Share';
import PaymentIcon from '@material-ui/icons/Payment';
import EditIcon from '@material-ui/icons/Edit';
import NumberFormat from 'react-number-format';
import BottomButtons from '../nav/BottomButtons';
import { whatsappLink, ButtonLink, debug, getForegroundColor, STRIPE_PUBLIC_KEY } from '../utils/utils';
import { loadStripe } from "@stripe/stripe-js";

let stripePromise = null;

const useStyles = makeStyles({
  paymentLink: {
    marginBottom: 8,
    backgroundColor: "var(--light-background-color)",
  },
  paymentLinkImage: {
    width: "100%",
    maxHeight: "100%",
    marginBottom: 8,
  },
  paymentLinkContent: {
    padding: 8,
  },
  paymentLinkName: {
    color: "var(--text-color)",
  },
  paymentLinkPrice: {
    color: "var(--mui-primary-color)",
    marginTop: 4,
  },
  paymentLinkSlashedPrice: {
    color: "var(--light-text-color)",
    textDecoration: "line-through",
    fontSize: 12,
  },
  paymentLinkDescription: {
    marginTop: 24,
    color: "var(--light-text-color)",
  },
  paymentLinkTags: {
    marginTop: 24,
  },
  tag: {
    marginRight: 8,
  },
  shop: {
    margin: "24px 0px",
  }
});

function CatalogPaymentLinkView(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');
  const [redirecting, setRedirecting] = useState(false);
  var previousBackgroundColor = null;
  var previousForegroundColor = null;
  var foregroundColor = null;
  let categories = null;

  useEffect(() => {
    if(props.paymentLinkId) props.load(props.paymentLinkId, t('shop.payment_link'));
  }, []);

  useEffect(() => {
    if(props.paymentLink && props.paymentLink.shopId && !props.shop) props.loadShop(props.paymentLink.shopId);
  }, [props.paymentLink]);

  useEffect(() => {
    let fg1 = getComputedStyle(document.querySelector(":root")).getPropertyValue("--text-color");
    let fg2 = getComputedStyle(document.querySelector(":root")).getPropertyValue("--dark-theme-text-color");
    if(props.shop) {
      if(props.shop.primaryColor) {
        previousBackgroundColor = getComputedStyle(document.body).backgroundColor;
        previousForegroundColor = getComputedStyle(document.body).color;
        // debug(`Changing background color; previous color ${previousBackgroundColor}`);
        document.body.style.backgroundColor = props.shop.primaryColor;
        document.getElementById("nav").style.backgroundColor = props.shop.primaryColor;
        foregroundColor = getForegroundColor(props.shop.primaryColor, fg1, fg2);
        document.body.style.color = foregroundColor;
      }

      if(!props.stripeSessionId) props.getSession(props.paymentLink, props.shop);
      if(!stripePromise) {
        fetchShopOwnerStripeAccountId(props.shop)
          .then( stripeAccountId => {
            debug(`Loading stripe with account id ${stripeAccountId}`);
            stripePromise = loadStripe(STRIPE_PUBLIC_KEY, { stripeAccount: stripeAccountId });
          });
      }
    }
    return function cleanup() {
      // debug(`cleanup: returning to color ${previousBackgroundColor}`);
      if (previousBackgroundColor) {
        document.body.style.backgroundColor = previousBackgroundColor;
        document.getElementById("nav").style.backgroundColor = previousBackgroundColor;
      }
      if (previousForegroundColor) document.body.style.color = previousForegroundColor;
    }
  }, [props.shop]);

  const stripeRedirect = async () => {
    debug('Redirecting to stripe checkout from payment link with id', props.stripeSessionId);
    setRedirecting(true);
    const stripe = await stripePromise;
    const result = await stripe.redirectToCheckout({
      sessionId: props.stripeSessionId,
    });
    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      debug('Could not redirect to Stripe', result.error);
      props.openStatusMessage(result.error.message);
    }
  }

  let { 
    name = null, description = null, currency = null, price = null, slashedPrice = null,
  } = props.paymentLink || {};

  let shopName = "";
  let shopNumber = "";
  if(props.shop) {
    shopName = props.shop.name;
    shopNumber = props.shop.number;
  }

  if(redirecting) return (
    <div className="feed" style={{ paddingBottom: 52 }}>
      <div className={classes.shope}>
        <Typography>{t('common.redirecting')}</Typography>
      </div>
    </div>
  );
  return (
    <div className="feed" style={{ paddingBottom: 52 }}>
      <div className={classes.paymentLink}>
        <div className={classes.paymentLinkContent}>
          <div className={classes.paymentLinkName}>
            {name || <Skeleton variant="text" width="200px" />}

            {props.canEdit && props.shop && 
              <Button aria-label="edit" 
                style={{marginLeft: 16}}
                component={ButtonLink}
                variant="outlined"
                to={`/shops/${props.shop.id}/payment_links/${props.paymentLinkId}/edit`}
                startIcon={<EditIcon />}
              >
                {t('buttons.edit')}
              </Button>
            }
          </div>
          <div className={classes.paymentLinkPrice}>
            {price ? 
              <NumberFormat
                value={price}
                prefix={currency}
                displayType={'text'}
                thousandSeparator={true} 
                decimalScale={2}
                fixedDecimalScale={true}
              />
              : <Skeleton variant="text" width="100px" />
            }
          </div>
          <div className={classes.paymentLinkDescription}>
            {description}
          </div>
        </div>
      </div>
      <div className={classes.shop}>
        {props.shop && 
          <Link to={`/${props.shop.handle}`}>
            {t('shop.see_more_shop', { name: shopName })} &raquo;
          </Link>
        }
      </div>

      <BottomButtons>
        <Button variant="outlined" style={{marginRight: 8, flexGrow: 0.2, }}
          size="small"
          href={whatsappLink(t('shop.share_url', { name: name }), window.location.href)}
          startIcon={<ShareIcon />}
        >
          {t('buttons.share')}
        </Button>

        <Button variant="outlined" style={{marginRight: 8, flexGrow: 0.2, }}
          size="small"
          href={whatsappLink(t('shop.hello', { name: shopName }), window.location.href, shopNumber)}
          startIcon={<WhatsAppIcon />}
        >
          {t('buttons.chat')}
        </Button>

        <Button variant="contained" color="primary" style={{ flexGrow: 1 }}
          size="small"
          startIcon={<PaymentIcon />}
          disabled={!props.stripeSessionId}
          onClick={e => stripeRedirect()}
        >
          {t('buttons.pay')}
        </Button>

      </BottomButtons>

    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const paymentLinkId = (ownProps.match && ownProps.match.params) ? ownProps.match.params.paymentLinkId : null;
  let paymentLink = null;
  if(paymentLinkId && state.paymentLinks && state.paymentLinks[paymentLinkId]) paymentLink = state.paymentLinks[paymentLinkId];
  let canEdit = false;
  if(paymentLink && state.ownShops) {
    if(state.ownShops[paymentLink.shopId]) canEdit = true;
  }

  return {
    paymentLink,
    paymentLinkId,
    shop: state.shop,
    canEdit,
    stripeSessionId: state.stripeSessionId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: (paymentLinkId, title) => {
      dispatch(setPage("paymentLink", title));
      dispatch(fetchPaymentLink(paymentLinkId));
    },
    loadShop: shopId => {
      dispatch(fetchShopById(shopId));
    },
    getSession: (payment, shop) => {
      const shopId = shop.id;
      debug(`Checkout.getSession with payment links ${payment.id} shop ${shopId}`, payment);
      if(payment) {
        let order = [{
          quantity: 1,
          product: payment,
        }];
        dispatch(fetchStripeSession(order, shop));
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogPaymentLinkView);
