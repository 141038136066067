import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { 
  isHandleAvailable, createShop, updateShop, fetchOwnShops, uploadFile, fetchShopProducts,
  fetchShopPaymentLinks, refreshShopLogoUrls, setPage,
} from '../store/actions';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ColorPicker from 'material-ui-color-picker';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import AddIcon from '@material-ui/icons/Add';
import UpgradeIcon from '@material-ui/icons/Publish';
import List from '@material-ui/core/List';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import BottomButtons from '../nav/BottomButtons';
import UploadingFile from './UploadingFile';
import ProductPreview from './ProductPreview';
import PaymentLinkPreview from './PaymentLinkPreview';
import LinkPreview from './LinkPreview';
import ActivationAlert from './ActivationAlert';
import { ScrollToTop, ButtonLink, debug, getCountry, currencies, getCurrency, getCurrencyName } from  '../utils/utils';
import ImageCaptureButton from '../utils/ImageCaptureButton';
import CountrySelect from '../utils/CountrySelect';

import { v4 as uuid } from 'uuid';
import moment from 'moment';

// const PRIMARY_COLOR = "#C62828";
const PRIMARY_COLOR = "#f6f2f2";
// const SECONDARY_COLOR = "#c0a0a0";
const SECONDARY_COLOR = "#C62828";

const useStyles = makeStyles({
  form: {
    paddingBottom: 52,
  },
  textfield: {
    minWidth: 300,
  },
  formControl: {
    marginTop: 24,
  },
  submitBtn: {
    position: "relative",
    top: 24,
  },
  formGroup: {
    marginTop: 24,
  },
  colorSwab: {
    width: 48,
    height: 40,
    margin: "8px 16px 0px 0px",
    border: "1px solid rgba(0, 0, 0, 0.42)",
    float: "left",
  },
  logoImage: {
    maxWidth: "100%",
    marginTop: 24,
  },
  uploadingFiles: {
    marginTop: 24,
  },
});

function InnerEditShop(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');
  const [validateHandle, setValidateHandle] = useState({ error: false, message: ""});
  const [handle, setHandle] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [number, setNumber] = useState(props.user.phoneNumber || "");
  const [primaryColor, setPrimaryColor] = useState(PRIMARY_COLOR);
  const [secondaryColor, setSecondaryColor] = useState(SECONDARY_COLOR);
  const [logo, setLogo] = useState({filename: null, url: null});
  const [uploadFiles, setUploadFiles] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [country, setCountry] = useState(getCountry || "");
  const [address, setAddress] = useState("");
  const [links, setLinks] = useState([]);
  const [linkName, setLinkName] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [currency, setCurrency] = useState(getCurrency() || "USD");
  const [serviceFees, setServiceFees] = useState(0);
  const [deliveryFees, setDeliveryFees] = useState(0);
  const [deliveryThreshold, setDeliveryThreshold] = useState(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const shopid = urlParams.get('shopid');
    if(props.shopId) {
      props.load(props.shopId, t('shop.edit_shop'));
    } else {
      props.load(null, t('shop.new_shop'));
      if(shopid) setHandle(shopid);
    }
  }, []);

  useEffect(() => {
    if(props.shop) {
      setHandle(props.shop.handle);
      setName(props.shop.name);
      setDescription(props.shop.description);
      if(props.shop.country) setCountry(props.shop.country);
      if(props.shop.address) setAddress(props.shop.address);
      if(props.shop.number) setNumber(props.shop.number);
      if(props.shop.links) setLinks(props.shop.links);
      setPrimaryColor(props.shop.primaryColor);
      setSecondaryColor(props.shop.secondaryColor);
      if(props.shop.logo) setLogo(props.shop.logo);
      if(props.shop.serviceFees) setServiceFees(props.shop.serviceFees);
      if(props.shop.deliveryFees) setDeliveryFees(props.shop.DeliveryFees);
      if(props.shop.deliveryThreshold) setDeliveryThreshold(props.shop.deliveryThreshold);
      if(props.shop.currency) setCurrency(props.shop.currency);
    }
  }, [props.shop]);

  const validate = () => {
    const slug = handle ? handle.trim() : null;

    if(!slug || slug.length===0) {
      setValidateHandle({ error: true, message: t('home.enter_url') });
      return false;
    }
    if(slug.length<6 || slug.length>64) {
      setValidateHandle({ error: true, message: t('home.url_length_error')});
      return false;
    }
    const pattern = /^[a-zA-Z0-9_]+$/;
    if(!pattern.test(slug)) {
      setValidateHandle({ error: true, message: t('home.url_chars_error') });
      return false;
    }

    if(!props.shop || (props.shop.handle && props.shop.handle.toLowerCase()!==slug.toLowerCase())) {
      isHandleAvailable(slug).then(avail => {
        if(avail) {
          debug(`Handle ${slug} is available`);
          setValidateHandle({ error: false, message: ""});
        } else {
          debug(`Handle ${slug} is not available`);
          setValidateHandle({ error: true, message: t('home.url_taken_error', { url: slug }) });
        }
      });
    }

    return true;
  }

  const checkHandle = e => {
    const valid = validate();
    if(e && !valid) e.preventDefault();
  }

  const handleFiles = e => {
    debug('Upload files:', e.target.files);
    if(e.target.files) {
      let files = [];
      for(let i=0; i<e.target.files.length; i++) {
        let file = e.target.files[i];
        debug('handleFiles iterating on file:',file);
        let extension = 'png';
        let folder = moment().format('YYYYMM');
        try {
          let i = file.name.lastIndexOf('.');
          extension = file.name.substring(i+1);
        } catch(err) {
          debug(`Could not extract extension from filename ${file.name}`, err);
        }
        let filename = `shops/${folder}/shoplogo-${uuid()}.${extension}`;
        debug(`Setting logo filename to ${filename}`);
        setLogo({...logo, filename});

        let fileObj = {
          name: file.name,
          filename: filename,
          progress: 0,
          failed: false,
          done: false,
          url: null,
        };
        files.push(fileObj);

        debug("Logo object before uploadFile is ", logo);
        uploadFile(
          filename, file, 
          p => {
            fileObj.progress = Math.floor(p);
            debug('Upload progress, fileObj:', fileObj);
            setLastUpdate(Date.now());
          }, 
          () => { fileObj.failed = true; setLastUpdate(Date.now()); }, 
          url => { 
            fileObj.url = url;
            fileObj.done = true;
            setLastUpdate(Date.now());
            // if(props.shopId) props.save(props.shopId, { logoURL: url }, true);
            let newLogo = {...logo, url, filename, max: null, mid: null, thumb: null};
            setLogo(newLogo);
            debug(`setLogo URL after upload to ${url}`, newLogo);
            props.refresh(props.shop);
          },
        );
      }
      setUploadFiles(files);
    }
  }

  const addLink = e => {
    e.preventDefault();
    if(!linkName || !linkUrl || linkName.trim().length==0 || linkUrl.trim().length==0)
      return;

    let newLinks = [...links];
    newLinks.push({
      name: linkName.trim(),
      url: linkUrl.trim(),
    });
    setLinks(newLinks);
    setLinkName("");
    setLinkUrl("");
  }

  const handleSubmit = e => {
    e.preventDefault();
    debug(`Saving shop with logo`, logo);
    props.save(props.shopId, {
      handle, name, description, number, primaryColor, secondaryColor,
      country, address, logo, links, currency,
      serviceFees, deliveryFees, deliveryThreshold,
    });
    props.history.push('/dashboard');
    setHandle("");
    setName("");
    setDescription("");
    setCountry("");
    setAddress("");
    setNumber("");
    setPrimaryColor(PRIMARY_COLOR);
    setSecondaryColor(SECONDARY_COLOR);
    setLogo({filename: null, url: null});
    setUploadFiles([]);
    setLinks([]);
    setServiceFees(0);
    setDeliveryFees(0);
    setDeliveryThreshold(0);
    setCurrency(getCurrency() || "USD");
  }

  let sortedProducts = [];
  if(props.products && props.shopId) {
    sortedProducts = Object.entries(props.products).sort((x, y) => y[1].createdAt - x[1].createdAt);
  }

  let sortedPaymentLinks = [];
  if(props.paymentLinks && props.shopId) {
    sortedPaymentLinks = Object.entries(props.paymentLinks).sort((x, y) => y[1].createdAt - x[1].createdAt);
  }

  let logoImg = logo.mid || logo.url;
  if(!logoImg && props.shop && props.shop.logo) logoImg = props.shop.logo.mid || props.shop.logo.url;
  // debug("ViewShop logoImg", logoImg);
 
  let finalizePayment = null;
  if(props.shop && props.shop.plan==="pending") {
    finalizePayment = (
      <ActivationAlert shopId={props.shopId} />
    );

  }

  return (
    <div className="feed">
      <ScrollToTop />
      <Typography variant="body2" color="textSecondary">
        {t('shop.editshop_intro')}
      </Typography>

      { finalizePayment }

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div className={classes.form}>
          <TextField
            label={t('home.shopid')}
            name="shopid"
            className={classes.textfield} 
            helperText={validateHandle.error ? validateHandle.message : t('home.shopid_helper')}
            value={handle}
            onChange={e => setHandle(e.target.value) }
            onBlur={validate}
            margin="normal"
            fullWidth
            error={validateHandle.error}
            InputProps={{
              startAdornment: <InputAdornment position="start">instashop.bio/</InputAdornment>
            }}
          />
          <TextField
            label={t('common.name')}
            name="name"
            required={true}
            className={classes.textfield}
            value={name}
            onChange={ e => setName(e.target.value) }
            margin="normal"
            fullWidth
          />

          <TextField
            label={t('common.description')}
            name="description"
            className={classes.textfield}
            value={description}
            onChange={ e => setDescription(e.target.value) }
            margin="normal"
            multiline={true}
            rows="3"
            fullWidth
          />

          <CountrySelect value={country} onChange={(e, newValue) => setCountry(newValue)} />

          <FormControl style={{ marginTop: 16, marginRight: 16 }}>
            <InputLabel>{t('common.currency')}</InputLabel>
            <Select
              native
              name="currency"
              value={currency}
              onChange={ e => setCurrency(e.target.value) }
            >
              {currencies.map( (c, i) => (<option key={`${c}_${i}`} value={c}>{getCurrencyName(c)}</option>) )}
            </Select>
          </FormControl>

          <TextField
            label={t('common.address')}
            helperText={t('shop.address_helper')}
            name="address"
            className={classes.textfield}
            value={address}
            onChange={ e => setAddress(e.target.value) }
            margin="normal"
            multiline={true}
            rows="2"
            fullWidth
          />

          <TextField
            label={t('shop.number')}
            name="description"
            className={classes.textfield}
            value={number}
            type="tel"
            onChange={ e => setNumber(e.target.value) }
            margin="normal"
            helperText={t('shop.number_helper')}
            fullWidth
          />

          <h3>{t('shop.brand')}</h3>
          <div className={classes.formGroup} >
            <div className={classes.colorSwab} style={{backgroundColor: primaryColor}}>
            </div>

            <div style={{display: "inline-block", }}>
              <ColorPicker
                label={t('shop.primary_color')}
                name="primary_color"
                value={primaryColor}
                onChange={ color => setPrimaryColor(color) }
                TextFieldProps={{ value: primaryColor, size: "small" }}
              />
            </div>
          </div>
            
          <div className={classes.formGroup}>
            <div className={classes.colorSwab} style={{backgroundColor: secondaryColor}}>
            </div>

            <div style={{display: "inline-block", }}>
              <ColorPicker
                label={t('shop.secondary_color')}
                name="secondary_color"
                value={secondaryColor}
                onChange={ color => setSecondaryColor(color) }
                TextFieldProps={{ value: secondaryColor }}
              />
            </div>
          </div>

          <FormGroup className={classes.formGroup}>
            <FormLabel>{t('shop.shop_logo')}</FormLabel>
            <div>
              { logoImg && <img src={logoImg} className={classes.logoImage} /> }
            </div>
            <FormControl className={classes.formControl}>
              <ImageCaptureButton label={t('shop.shop_logo')} handleFiles={ handleFiles } />
              <FormHelperText>
                {t('shop.shop_logo_helper')}
              </FormHelperText>
            </FormControl>
          </FormGroup>

          <div className={classes.uploadingFiles}>
            {uploadFiles && uploadFiles.map( (x, i) => (
              <UploadingFile key={`uploading-${i}`} file={x} />
            ))}
          </div>


          <FormGroup className={classes.formGroup}>
            <h3>{t('shop.links')}</h3>
            <TextField
              label={t('shop.link_name')}
              name="Name"
              value={linkName}
              onChange={ e => setLinkName(e.target.value) }
              margin="normal"
              helperText={t('shop.link_name_helper')}
              fullWidth
            />

            <TextField
              label={t('shop.link_url')}
              name="URL"
              value={linkUrl}
              type="url"
              onChange={ e => setLinkUrl(e.target.value) }
              margin="normal"
              helperText={t('shop.link_url_helper')}
              fullWidth
            />
            <Button
              variant="outlined"
              color="primary"
              className={classes.addBtn}
              onClick={addLink}
            >
              {t('shop.add_link_btn')}
            </Button>

            <List>
              {links.filter(x => !x.deleted).map( (x, i) => (
                <LinkPreview
                  key={`link-${i}`}
                  link={x}
                  deleteLink={ e => {
                    x.deleted=true;
                    setLinks([...links]);
                  } }
                />
              ))}
            </List>

          </FormGroup>

          <FormGroup className={classes.formGroup}>
            <h3>{t('shop.fees')}</h3>

            <TextField
              label={t('shop.service_fees')}
              name="service_fees"
              className={classes.textfield}
              value={serviceFees}
              type="number"
              onChange={ e => setServiceFees(e.target.value) }
              margin="normal"
              helperText={t('shop.service_fees_helper')}
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />

            <TextField
              label={t('shop.delivery_fees')}
              name="delivery_fees"
              className={classes.textfield}
              value={deliveryFees}
              type="number"
              onChange={ e => setDeliveryFees(e.target.value) }
              margin="normal"
              helperText={t('shop.delivery_fees_helper')}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">{currency}</InputAdornment>
              }}
            />

            <TextField
              label={t('shop.delivery_threshold')}
              name="delivery_threshold"
              className={classes.textfield}
              value={deliveryThreshold}
              type="number"
              onChange={ e => setDeliveryThreshold(e.target.value) }
              margin="normal"
              helperText={t('shop.delivery_threshold_helper')}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">{currency}</InputAdornment>
              }}
            />
          </FormGroup>


          {props.shopId &&
            <React.Fragment>
              <h3>{t('shop.products')}</h3>
              <Button
                variant="contained"
                color="primary"
                className={classes.addBtn}
                startIcon={<AddIcon />}
                component={ButtonLink}
                to={`/shops/${props.shopId}/products/new`}
              >
                {t('shop.add_product')}
              </Button>
              <List>
                {sortedProducts.map( (x, i) => <ProductPreview key={`product-${i}`} product={x[1]} productId={x[0]} /> )}
              </List>

              <h3>{t('shop.payment_links')}</h3>
              <Button
                variant="contained"
                color="primary"
                className={classes.addBtn}
                startIcon={<AddIcon />}
                component={ButtonLink}
                to={`/shops/${props.shopId}/payment_links/new`}
              >
                {t('shop.add_payment_link')}
              </Button>
              <List>
                {sortedPaymentLinks.map( (x, i) => <PaymentLinkPreview key={`paymentLink-${i}`} paymentLink={x[1]} paymentLinkId={x[0]} /> )}
              </List>
            </React.Fragment>
          }


          {props.shop && props.shop.plan!=='pro' &&
            <div>
              <h3>{t('plans.plans')}</h3>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {t('plans.upgrade_description')}
              </Typography>
              <Button
                variant="contained"
                color="default"
                className={classes.addBtn}
                startIcon={<UpgradeIcon />}
                component={ButtonLink}
                to={`/shops/${props.shopId}/pay`}
              >
                {t('plans.upgrade')}
              </Button>
            </div>
          }

          <BottomButtons>
            <Button fullWidth variant="outlined" style={{marginRight: 16}}
              to="/dashboard"
              component={ButtonLink}
            >
              {t('buttons.back')}
            </Button>

            <Button fullWidth variant="contained" color="primary" 
              onClick={checkHandle}
              type="submit"
            >
              {t('buttons.save')}
            </Button>

          </BottomButtons>


        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const shopId = (ownProps.match && ownProps.match.params) ? ownProps.match.params.shopId : null;
  var plan   = (ownProps.match && ownProps.match.params && ownProps.match.params.plan) ? ownProps.match.params.plan : "free";
  let shop = null;
  if(shopId && state.ownShops && state.ownShops[shopId]) shop = state.ownShops[shopId];
  if(shop && shop.plan) plan = shop.plan;

  debug('shopId', shopId, 'shop', shop);
  return {
    shopId,
    shop,
    plan,
    products: state.products,
    paymentLinks: state.paymentLinks,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    save: (shopId, shop, merge=false) => {
      if(shopId) dispatch(updateShop(shopId, shop, merge));
      else dispatch(createShop(shop));
    },
    load: (shopId, title, description) => {
      dispatch(setPage("edit_shop", title, description));
      dispatch(fetchOwnShops());
      if(shopId) {
        dispatch(fetchShopProducts(shopId));
        dispatch(fetchShopPaymentLinks(shopId));
      }
    },
    refresh: (shop) => {
      dispatch(refreshShopLogoUrls(shop));
    },
  }
}

const EditShop = connect(mapStateToProps, mapDispatchToProps)(InnerEditShop);
export default EditShop;

