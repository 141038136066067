import React from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { selectTab } from '../store/actions';

import StoreIcon from '@material-ui/icons/Store';
import NotesIcon from '@material-ui/icons/Notes';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

const useStyles = makeStyles({
  nav: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 100,
  },
  action: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

function BottomNavBar(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');
  return (
    <BottomNavigation showLabels className={classes.nav}
        value={props.selectedTab}
        onChange={(e,v) => props.selectTab(v)}
    >
      <BottomNavigationAction
        className={classes.action}
        label={t('nav.dashboard')}
        value="dashboard"
        component={Link}
        to="/dashboard"
        icon={<StoreIcon/>}
      />

      <BottomNavigationAction
        className={classes.action}
        label={t('nav.fulfilment')}
        value="fulfilment"
        component={Link}
        to="/fulfilment"
        icon={<NotesIcon/>}
      />
    </BottomNavigation>
  );

}

const mapStateToProps = (state, ownProps) => {
  const url = ownProps.location.pathname;
  let tab;
  if(url=="/dashboard") tab = "dashboard";
  else if(url=="/fulfilment") tab = "fulfilment";
  else tab = state.selectedTab;
  
  return {
    selectedTab: tab,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    selectTab: (tab) => {
      dispatch(selectTab(tab));
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BottomNavBar);
