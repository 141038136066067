import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ButtonLink } from  '../utils/utils';

const useStyles = makeStyles({
  payBtn: {
    marginTop: 16,
    textAlign: "center",
  },
});

function ActivationAlert(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');
  return (
    <Alert severity="warning">
      <AlertTitle>{t('shop.payment_pending_title')}</AlertTitle>
      {t('shop.payment_pending_description')}
      <div className={classes.payBtn}>
        <Button component={ButtonLink} to={`/shops/${props.shopId}/pay`} variant="outlined">
          {t('shop.pay')}
        </Button>
      </div>
    </Alert>
  );
}

export default ActivationAlert;
