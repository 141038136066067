import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { 
  isHandleAvailable, fetchOwnShops, uploadFile, createProduct, updateProduct, fetchProduct,
  refreshProductImageUrls, setPage,
} from '../store/actions';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';

import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { ButtonLink, ScrollToTop, debug, currencies, getCurrency, getCurrencyName, } from  '../utils/utils';
import UploadingFile from './UploadingFile';

import ImageCaptureButton from '../utils/ImageCaptureButton';
import BottomButtons from '../nav/BottomButtons';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

const PRIMARY_COLOR = "#402020";
const SECONDARY_COLOR = "#c0a0a0";

const useStyles = makeStyles({
  form: {
    paddingBottom: 52,
  },
  textfield: {
    minWidth: 300,
  },
  formControl: {
    marginTop: 24,
  },
  submitBtn: {
    position: "relative",
    top: 24,
  },
  formGroup: {
    marginTop: 24,
  },
  colorSwab: {
    width: 48,
    height: 40,
    margin: "8px 16px 0px 0px",
  },
  productImages: {
    display: "flex",
    marginTop: 24,
  },
  productImageContainer: {
    width: 200,
    height: 200,
    marginRight: 16,
    position: "relative",
  },
  productImage: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  uploadingFiles: {
    marginTop: 24,
  },
  imageDeleteBtn: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});

function EditProduct(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');
  const [published, setPublished] = useState(true);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState("");
  const [images, setImages] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [currency, setCurrency] = useState(getCurrency() || "USD");
  const [price, setPrice] = useState("");
  const [slashedPrice, setSlashedPrice] = useState("");
  const [pickupAvailable, setPickupAvailable] = useState(true);
  const [deliveryAvailable, setDeliveryAvailable] = useState(true);
  const [inventory, setInventory] = useState("");

  useEffect(() => {
    if(props.productId) {
      props.load(props.productId, t('shop.edit_product'));
    } else {
      props.load(null, t('shop.add_product'));
    }
  }, []);

  useEffect(() => {
    if(props.product) {
      setPublished(!!props.product.published);
      setName(props.product.name);
      setDescription(props.product.description);
      if(props.product.tags) setCategories(props.product.tags.join(" "));
      setImages(props.product.images);
      setCurrency(props.product.currency);
      setPrice(props.product.price);
      setSlashedPrice(props.product.slashedPrice);
      if(props.product.pickupAvailable!==undefined) setPickupAvailable(props.product.pickupAvailable);
      if(props.product.deliveryAvailable!==undefined) setDeliveryAvailable(props.product.deliveryAvailable);
      if(props.product.inventory!==undefined && Number.isInteger(props.product.inventory))
        setInventory(props.product.inventory);
      else setInventory("");
    }
  }, [props.product]);

  const handleFiles = e => {
    debug('Upload files:', e.target.files);
    if(e.target.files) {
      let files = [];
      let productImages = images || [];
      for(let i=0; i<e.target.files.length; i++) {
        let file = e.target.files[i];
        debug('handleFiles iterating on file:',file);
        let extension = 'png';
        let folder = moment().format('YYYYMM');
        try {
          let i = file.name.lastIndexOf('.');
          extension = file.name.substring(i+1);
        } catch(err) {
          debug(`Could not extract extension from filename ${file.name}`, err);
        }
        let filename = `products/${folder}/product-${uuid()}.${extension}`;
        let image = {
          filename,
          url: null,
        }
        productImages.push(image);

        let fileObj = {
          name: file.name,
          filename: filename,
          progress: 0,
          failed: false,
          done: false,
          url: null,
        };
        files.push(fileObj);

        uploadFile(
          filename, file, 
          p => {
            fileObj.progress = Math.floor(p);
            debug('Upload progress, fileObj:', fileObj);
            setLastUpdate(Date.now());
          }, 
          () => { fileObj.failed = true; setLastUpdate(Date.now()); }, 
          url => { 
            fileObj.url = url;
            fileObj.done = true;
            image.url = url;
            setLastUpdate(Date.now());
            debug('set image url after upload to', url);
            props.refresh(props.product);
          },
        );
      }
      setUploadFiles(files);
      setImages(productImages);
    }
  }

  const handleDeleteImage = filename => {
    debug(`Deleting image ${filename}`);
    let productImages = [...images];
    for(let i=0; i<productImages.length; i++) {
      if(productImages[i].filename === filename) {
        productImages[i].deleted = true;
        break;
      }
    }
    setImages(productImages);
  }

  // debug('UploadFiles:', uploadFiles);

  const handleSubmit = e => {
    e.preventDefault();
    let tags = [];
    let quantity = null;
    if(categories) {
      tags = categories.trim().split(/[\s,;]+/);
    }
    quantity = parseInt(inventory);
    if(isNaN(quantity)) quantity = null;

    props.save(props.shopId, props.productId, {
      published, name, description, tags, images,
      currency, price, slashedPrice,
      pickupAvailable, deliveryAvailable, inventory: quantity,
    });
    setPublished(true);
    setName("");
    setDescription("");
    setCategories("");
    setImages([]);
    setCurrency(getCurrency() || "USD");
    setPrice("");
    setSlashedPrice("");
    setPickupAvailable(true);
    setDeliveryAvailable(true);
    setInventory("");
    props.history.push(`/shops/${props.shopId}/edit`);
  }

  return (
    <div className="feed">
      <ScrollToTop />
      <Typography variant="body2" color="textSecondary">
        {t('shop.editproduct_intro')}
      </Typography>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div className={classes.form}>

          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={published}
                onChange={ e => setPublished(e.target.checked) }
                name="published"
              />}
            label={t('shop.publish')}
          />
            

          <TextField
            label={t('common.name')}
            name="name"
            required={true}
            className={classes.textfield}
            value={name}
            onChange={ e => setName(e.target.value) }
            margin="normal"
            fullWidth
          />

          <TextField
            label={t('common.description')}
            name="description"
            className={classes.textfield}
            value={description}
            onChange={ e => setDescription(e.target.value) }
            margin="normal"
            multiline={true}
            rows="3"
            fullWidth
          />

          <TextField
            label={t('shop.categories')}
            name="categories"
            className={classes.textfield}
            value={categories}
            onChange={ e => setCategories(e.target.value) }
            margin="normal"
            helperText={t('shop.categories_helper')}
            fullWidth
          />

          <FormGroup row>
            <FormControl style={{ marginTop: 16, marginRight: 16 }}>
              <InputLabel>{t('common.currency')}</InputLabel>
              <Select
                native
                name="currency"
                value={currency}
                onChange={ e => setCurrency(e.target.value) }
              >
                {currencies.map( (c, i) => (<option key={`${c}_${i}`} value={c}>{getCurrencyName(c)}</option>) )}
              </Select>
            </FormControl>
            <TextField
              label={t('common.price')}
              name="price"
              type="number"
              className={classes.textField}
              value={price}
              onChange={ e => setPrice(e.target.value) }
              margin="normal"
              InputProps={{
                startAdornment: <InputAdornment position="start">{currency}</InputAdornment>
              }}
            />
          </FormGroup>

          <TextField
            label={t('shop.slashed_price')}
            name="slahed_price"
            type="number"
            className={classes.textField}
            value={slashedPrice}
            onChange={ e => setSlashedPrice(e.target.value) }
            margin="normal"
            InputProps={{
              startAdornment: <InputAdornment position="start">{currency}</InputAdornment>
            }}
            helperText={t('shop.slashed_price_helper')}
          />

          <FormGroup className={classes.formGroup}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={pickupAvailable}
                  onChange={ e => setPickupAvailable(e.target.checked) }
                  name="pickup_available"
                />}
              label={t('shop.pickup_available')}
            />
              
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={deliveryAvailable}
                  onChange={ e => setDeliveryAvailable(e.target.checked) }
                  name="delivery_available"
                />}
              label={t('shop.delivery_available')}
            />

            <TextField
              label={t('shop.inventory')}
              name="inventory"
              type="number"
              className={classes.textField}
              value={inventory}
              onChange={ e => setInventory(e.target.value) }
              margin="normal"
              helperText={t('shop.inventory_helper')}
            />


          </FormGroup>

            
          <FormGroup className={classes.formGroup}>
            <FormLabel>{t('shop.product_images')}</FormLabel>
            <FormControl className={classes.formControl}>
              <ImageCaptureButton
                label={t('shop.product_images')} 
                multiple={true}
                handleFiles={ handleFiles } />
              <FormHelperText>
                {t('shop.product_images_helper')}
              </FormHelperText>
            </FormControl>

          </FormGroup>

          <div className={classes.uploadingFiles}>
            {uploadFiles && uploadFiles.map( (x, i) => (
              <UploadingFile key={`uploading-${i}`} file={x} />
            ))}
          </div>

          <div className={classes.productImages}>
            { images && images.filter(x => (!x.deleted && !!x.url)).map( (x, i) => 
              <div key={`image-${i}`} className={classes.productImageContainer}>
                <img src={x.mid || x.url} className={classes.productImage} />
                <IconButton aria-label="delete"
                    className={classes.imageDeleteBtn} 
                    onClick={ e => handleDeleteImage(x.filename) }
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>

          <BottomButtons>
            <Button fullWidth variant="outlined" style={{marginRight: 16}}
              to={`/shops/${props.shopId}/edit`}
              component={ButtonLink}
            >
              {t('buttons.back')}
            </Button>

            <Button fullWidth variant="contained" color="primary" 
              type="submit"
            >
              {t('buttons.save')}
            </Button>

          </BottomButtons>


        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const shopId = (ownProps.match && ownProps.match.params) ? ownProps.match.params.shopId : null;
  let shop = null;
  if(shopId && state.ownShops && state.ownShops[shopId]) shop = state.ownShops[shopId];
  debug('shopId', shopId, 'shop', shop);

  const productId = (ownProps.match && ownProps.match.params) ? ownProps.match.params.productId : null;
  let product = null;
  if(productId && state.products && state.products[productId]) product = state.products[productId];
  debug('productId', productId, 'product', product);

  if(product && shop && product.shopId !== shopId) {
    debug(`EditProduct, product ${productId} is not in shop ${shopId}`);
    return {};
  }

  return {
    productId,
    product,
    shopId,
    shop,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    save: (shopId, productId, product, merge=false) => {
      if(productId) dispatch(updateProduct(productId, product, merge));
      else dispatch(createProduct(shopId, product));
    },
    load: (productId, title, description) => {
      dispatch(setPage("edit_product", title, description));
      if(productId) dispatch(fetchProduct(productId))
    },
    refresh: (product) => {
      dispatch(refreshProductImageUrls(product));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct);
