import React, { useState } from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';

import { CenteredCircularProgress } from '../utils/utils';
import HomePage from './HomePage';

function InnerLanding(props) {
  if(props.isLoggingIn) return <CenteredCircularProgress />;
  if(props.isLoggedIn) return <Redirect to="/dashboard" />;
  return <HomePage />;
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.isLoggedIn,
    isLoggingIn: state.isLoggingIn,
    user: state.user,
  };
}

const Landing = connect(mapStateToProps)(InnerLanding);
export default Landing;


