import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { fetchProduct, fetchShopById, addToCart, setPage, refreshProductImageUrls } from '../store/actions';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ShareIcon from '@material-ui/icons/Share';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import EditIcon from '@material-ui/icons/Edit';
import NumberFormat from 'react-number-format';
import Typography from '@material-ui/core/Typography';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import BottomButtons from '../nav/BottomButtons';
import { whatsappLink, ButtonLink, debug, getForegroundColor, ScrollToTop } from '../utils/utils';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles({
  product: {
    marginBottom: 8,
    backgroundColor: "var(--light-background-color)",
  },
  productImage: {
    width: "100%",
    maxHeight: "100%",
    marginBottom: 8,
  },
  productContent: {
    padding: 8,
  },
  productName: {
    color: "var(--text-color)",
  },
  productPrice: {
    color: "var(--mui-primary-color)",
    marginTop: 4,
  },
  productSlashedPrice: {
    color: "var(--light-text-color)",
    textDecoration: "line-through",
    fontSize: 12,
  },
  productDescription: {
    marginTop: 24,
    color: "var(--light-text-color)",
  },
  productTags: {
    marginTop: 24,
  },
  tag: {
    marginRight: 8,
  },
  shop: {
    margin: "24px 0px",
  }
});

function CatalogProductView(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');
  var previousBackgroundColor = null;
  var previousForegroundColor = null;
  var foregroundColor = null;
  let categories = null;

  useEffect(() => {
    if(props.productId) props.load(props.productId, t('shop.product'));
  }, []);

  useEffect(() => {
    if(props.product && props.product.shopId && !props.shop) props.loadShop(props.product.shopId);
    if(props.product && props.product.images && props.product.images.length>0) props.refresh(props.product);
  }, [props.product]);

  useEffect(() => {
    let fg1 = getComputedStyle(document.querySelector(":root")).getPropertyValue("--text-color");
    let fg2 = getComputedStyle(document.querySelector(":root")).getPropertyValue("--dark-theme-text-color");
    if(props.shop) {
      if(props.shop.primaryColor) {
        previousBackgroundColor = getComputedStyle(document.body).backgroundColor;
        previousForegroundColor = getComputedStyle(document.body).color;
        // debug(`Changing background color; previous color ${previousBackgroundColor}`);
        document.body.style.backgroundColor = props.shop.primaryColor;
        document.getElementById("nav").style.backgroundColor = props.shop.primaryColor;
        foregroundColor = getForegroundColor(props.shop.primaryColor, fg1, fg2);
        document.body.style.color = foregroundColor;
      }
    }
    return function cleanup() {
      // debug(`cleanup: returning to color ${previousBackgroundColor}`);
      if (previousBackgroundColor) {
        document.body.style.backgroundColor = previousBackgroundColor;
        document.getElementById("nav").style.backgroundColor = previousBackgroundColor;
      }
      if (previousForegroundColor) document.body.style.color = previousForegroundColor;
    }
  }, [props.shop]);

  let { 
    name = null, description = null, currency = null, price = null, slashedPrice = null,
  } = props.product || {};

  if(props.shop && props.product && props.product.tags && props.product.tags.length>0) {
    categories = props.product.tags.map( (tag, i) => (
      <Link className={classes.tag} key={`tag-${i}`} to={`/${props.shop.handle}/${tag}`}>{tag}</Link>
    ));
  }
  let carousel = null;

  let firstImage = null;
  if(props.product && props.product.images && props.product.images.length>0) {
    let images = props.product.images.filter(x=>!x.deleted);
    debug(`CatalogProductView ${props.product.name} images`, images);
    if(images.length>1) {
      carousel = (
        <div className={classes.carousel}>
          <Carousel>
            {images.map( (image,i) => {
              // debug(`CatalogProductView image ${i}: ${image.max} ${image.filename} ${image.url}`, image);
              if(i===0) firstImage = image.max || image.url;
              return (
                <div key={i}>
                  <img src={image.max || image.url} />
                </div>
              );
            })}
          </Carousel>
        </div>
      );
    } else {
      carousel = (
        <img
          className={classes.productImage}
          src={images[0].max || images[0].url}
        />
      );
    }
  }

  let shopName = "";
  let shopNumber = "";
  if(props.shop) {
    shopName = props.shop.name;
    shopNumber = props.shop.number;
  }

  if(props.product && props.product.published===false) {
    return (
      <div className="feed bottombar">
        <ScrollToTop />
        <div className={classes.product}>
          <div className={classes.productContent}>
            <div className={classes.productName}>
              <h1>
                {t('shop.product_not_found')}
              </h1>
            </div>
            <div className={classes.productDescription}>
              <Typography color="textSecondary" gutterBottom>
                {t('shop.product_not_found_description')}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.shop}>
          {props.shop && 
            <Link to={`/${props.shop.handle}`}>
              {t('shop.see_more_shop', { name: shopName })} &raquo;
            </Link>
          }
        </div>
      </div>
    );
  }


  var json_ld = null;
  if(props.product && props.shop) {
    json_ld = {
        "@context": "http://schema.org",
        "@type": "Product",
        "productID": props.product.id,
        "name": props.product.name,
        "description": props.product.description,
        "url": window.location.href,
        "image": firstImage,
        "brand": props.shop.name,
        "offers":[{
          "@type":"Offer",
          "price": props.product.price,
          "priceCurrency": props.product.currency,
          "itemCondition":"https://schema.org/NewCondition",
          "availability":"https://schema.org/InStock"
          }]
    };
  }

  return (
    <div className="feed bottombar">
      <ScrollToTop />
          
      <Helmet>
        <title>{`${props.shop ? props.shop.name : "Shop"} - ${props.product ? props.product.name : "Product"} | instashop.bio`}</title>
        <meta name="description" content={props.shop && props.shop.description} />
        <script type="application/ld+json">
          {JSON.stringify(json_ld)}
        </script>
      </Helmet>

      <div className={classes.product}>
        {carousel || <Skeleton variant="rect" width="100%" height="300px" />}
        <div className={classes.productContent}>
          <div className={classes.productName}>
            {name || <Skeleton variant="text" width="200px" />}

            {props.canEdit && props.shop && 
              <Button aria-label="edit" 
                style={{marginLeft: 16}}
                component={ButtonLink}
                variant="outlined"
                to={`/shops/${props.shop.id}/products/${props.productId}/edit`}
                startIcon={<EditIcon />}
              >
                {t('buttons.edit')}
              </Button>
            }
          </div>
          <div className={classes.productPrice}>
            {price ? 
              <NumberFormat
                value={price}
                prefix={currency}
                displayType={'text'}
                thousandSeparator={true} 
                decimalScale={2}
                fixedDecimalScale={true}
              />
              : <Skeleton variant="text" width="100px" />
            }
          </div>
          <div className={classes.productSlashedPrice}>
            {slashedPrice &&
              <NumberFormat
                value={slashedPrice}
                prefix={currency}
                displayType={'text'}
                thousandSeparator={true} 
                decimalScale={2}
                fixedDecimalScale={true}
              />
            }
          </div>
          <div className={classes.productDescription}>
            {description}
          </div>
          <div className={classes.productTags}>
            {categories}
          </div>
        </div>
      </div>
      <div className={classes.shop}>
        {props.shop && 
          <Link to={`/${props.shop.handle}`}>
            {t('shop.see_more_shop', { name: shopName })} &raquo;
          </Link>
        }
      </div>

      <BottomButtons>
        <Button variant="outlined" style={{marginRight: 8, flexGrow: 0.2, }}
          size="small"
          href={whatsappLink(t('shop.share_url', { name: name }), window.location.href)}
          startIcon={<ShareIcon />}
        >
          {t('buttons.share')}
        </Button>

        <Button variant="outlined" style={{marginRight: 8, flexGrow: 0.2, }}
          size="small"
          href={whatsappLink(t('shop.hello', { name: shopName }), window.location.href, shopNumber)}
          startIcon={<WhatsAppIcon />}
        >
          {t('buttons.chat')}
        </Button>

        <Button variant="contained" color="primary" style={{ flexGrow: 1 }}
          size="small"
          startIcon={<AddShoppingCartIcon />}
          onClick={e => props.addToCart(props.product)}
        >
          {t('buttons.add_to_cart')}
        </Button>

      </BottomButtons>

    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const productId = (ownProps.match && ownProps.match.params) ? ownProps.match.params.productId : null;
  let product = null;
  if(productId && state.products && state.products[productId]) product = state.products[productId];
  let canEdit = false;
  if(product && state.ownShops) {
    if(state.ownShops[product.shopId]) canEdit = true;
  }

  return {
    product,
    productId,
    shop: state.shop,
    canEdit,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: (productId, title) => {
      dispatch(setPage("product", title));
      dispatch(fetchProduct(productId));
    },
    loadShop: shopId => {
      dispatch(fetchShopById(shopId));
    },
    addToCart: product => {
      dispatch(addToCart(product));
    },
    refresh: (product) => {
      dispatch(refreshProductImageUrls(product));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogProductView);
