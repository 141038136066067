import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/storage';
import 'firebase/functions';

import ReactPixel from 'react-facebook-pixel';

import firestore from './firestore';
import localstore from './localstore';
import { debug } from '../utils/utils';

export const setPage = (nav, title, description) => ({type: "SET_PAGE", nav, title, description});
export const loginEvent = (user) => ({type: "LOGIN_EVENT", user});
export const loggingInEvent = () => ({type: "LOGGING_IN_EVENT"});
export const receiveProfile = (profile) => ({type: "RECEIVE_PROFILE", profile});
export const updateSession = (session) => ({type: "UPDATE_SESSION", session});
export const openStatusMessage = (message) => ({type: "OPEN_STATUS_MESSAGE", message: message});
export const closeStatusMessage = () => ({type: "CLOSE_STATUS_MESSAGE"});

export const receiveOwnShops = shops => ({type: "RECEIVE_OWN_SHOPS", shops});
export const receiveShop = shop => ({type: "RECEIVE_SHOP", shop});
export const addShop = shop => ({type: "ADD_SHOP", shop});

export const receiveProducts = products => ({type: "RECEIVE_PRODUCTS", products});
export const receivePaymentLinks = paymentLinks => ({type: "RECEIVE_PAYMENT_LINKS", paymentLinks});
export const receiveCart = cart => ({type: "RECEIVE_CART", cart});

export const receiveStripeSession = sessionId => ({type: "RECEIVE_STRIPE_SESSION", sessionId});
export const receiveStripeAccount = account => ({type: "RECEIVE_STRIPE_ACCOUNT", account});
export const receiveStripeAccountLink = url => ({type: "RECEIVE_STRIPE_ACCOUNT_LINK", url});
export const receiveStripeLoginLink = url => ({type: "RECEIVE_STRIPE_LOGIN_LINK", url});

export const selectTab = tab => ({ type: "SELECT_TAB", tab });

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  localStorage.setItem('debug', 'instashopbio:*');
} else {
  // production code
}

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBoDQpt9XVs-TYjLtf04Rb-0DIIfwKSZz4",
  authDomain: "instashopbio.firebaseapp.com",
  databaseURL: "https://instashopbio.firebaseio.com",
  projectId: "instashopbio",
  storageBucket: "instashopbio.appspot.com",
  messagingSenderId: "342939138592",
  appId: "1:342939138592:web:ed185c359046e30d799a45",
  measurementId: "G-240P63FS21"
};

firebase.initializeApp(firebaseConfig);
firestore.initialize();

const analytics = firebase.analytics();
ReactPixel.init('831228944324108');

/* Remote firebase functions */
const functions = firebase.functions();
if (window.location.hostname === "localhost") {
  debug(`Using function emulator on localhost`);
  functions.useEmulator("localhost", 5001);
} else {
  debug(`Using production functions ${window.location.hostname}`);
}
const stripeCheckoutSession = functions.httpsCallable('stripeCheckoutSession');
const stripeInstashopCheckoutSession = functions.httpsCallable('stripeInstashopCheckoutSession');
const stripeCreateAccount = functions.httpsCallable('stripeCreateAccount');
const stripeGetAccount = functions.httpsCallable('stripeGetAccount');
const stripeCreateAccountLink = functions.httpsCallable('stripeCreateAccountLink');
const stripeCreateLoginLink = functions.httpsCallable('stripeCreateLoginLink');
const getShopStripeAccountId = functions.httpsCallable('getShopStripeAccountId');


export function firebaseLogin() {
  return function(dispatch, getState) {
    dispatch(loggingInEvent());
    dispatch(fetchCart());

    // Setup phone authorization callbacks
    firebase.auth().onAuthStateChanged(function(user) {
      if(user) {
        dispatch(loginEvent(user));
        analytics.logEvent('login'); 
        analytics.setUserId(user.uid);
        ReactPixel.track('Lead');
        debug("Firebase logged-in user");
        dispatch(fetchProfile());
        dispatch(fetchOwnShops());
      } else {
        debug("Not logged-in user");
        dispatch(loginEvent(null));
      }

    });
  }
}

export function firebaseLogout() {
  return function(dispatch, getState) {
    debug("Logging out");
    dispatch(loginEvent(null));
    firebase.auth().signOut();
  }
}

export function updateProfile(user) {
  return function(dispatch, getState) {
    debug(`Updating user`, user);
    firestore.updateUser(user)
      .then(profile => {
        if(profile) dispatch(receiveProfile(profile));
      })
      .catch(error => {
        debug(`Could not update profile`, user, error);
        openStatusMessage('errors.could_not_update_user');
      });
  }
}

export function fetchProfile() {
  return function(dispatch, getState) {
    firestore.fetchUser()
      .then(profile => dispatch(receiveProfile(profile)))
      .catch(error => {
        debug(`Could not fetch profile`, error);
      });
  }
}

export function isHandleAvailable(handle) {
  debug(`Checking if handle ${handle} is available`);
  return firestore.isHandleAvailable(handle);
}

export function validateHandle(handle) {
  return firestore.validateHandle(handle);
}

export function createShop(shop) {
  return function(dispatch, getState) {
    debug(`Creating shop ${shop.handle}`);
    firestore.createShop(shop)
      .then(shop => {
        if(shop) {
          dispatch(addShop({[shop.id]: shop.data()}));
        }
      })
      .catch(error => {
        debug(`Could not create shop ${shop.handle}`, error);
        openStatusMessage('errors.could_not_create_shop');
      });
  }
}

export function updateShop(shopId, shop, merge=false) {
  return function(dispatch, getState) {
    debug(`Updating shop ${shopId}`, shop);
    firestore.updateShop(shopId, shop, merge)
      .then(shop => {
        if(shop) {
          dispatch(addShop({[shop.id]: shop.data()}));
        }
      })
      .catch(error => {
        debug(`Could not update shop ${shop.handle}`, error);
        openStatusMessage('errors.could_not_update_shop');
      });
  }
}

export function uploadFile(filename, file, progressCallback, failCallback, successCallback) {
  let storageRef = firebase.storage().ref(`upload/${filename}`);
  let uploadTask = storageRef.put(file);
  uploadTask.on('state_changed', function progress(snapshot) {
    var percentage = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
    debug(`Upload progress file ${file.name}: ${percentage}`);
    if(progressCallback) progressCallback(percentage);
  }, function error(err) {
    debug('Upload failed due to error', err);
    openStatusMessage('errors.upload_filed');
    if(failCallback) failCallback();

  }, function complete() {
    debug(`Upload file ${file.name} complete`);
    uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
      debug('File available at', downloadURL);
      if(successCallback) successCallback(downloadURL);
    });
    if(progressCallback) progressCallback(100);
  });
}

export function fetchOwnShops() {
  return function(dispatch, getState) {
    debug("Fetching own shops");
    firestore.fetchOwnShops()
      .then(shops => dispatch(receiveOwnShops(shops)))
      .catch(error => debug("Could not fetch own shops due to exception", error));
  }
}

export function fetchShopByHandle(handle) {
  return function(dispatch, getState) {
    debug(`Fetching shop ${handle}`);
    firestore.fetchShopByHandle(handle)
      .then(shop => dispatch( receiveShop(shop) ))
      .catch(error => debug(`Could not fetch shop ${handle} due to exception`, error));
  }
}

export function fetchShopById(shopId) {
  return function(dispatch, getState) {
    debug(`Fetching shop by id ${shopId}`);
    firestore.fetchShopById(shopId)
      .then(shop => dispatch( receiveShop(shop) ))
      .catch(error => debug(`Could not fetch shop by ID ${shopId} due to exception`, error));
  }
}

export function fetchShopOwner(shopId, shop) {
  return firestore.fetchShopOwner(shopId, shop);
}

export function fetchShopOwnerStripeAccountId(shop) {
  return getShopStripeAccountId({ shop: shop})
    .then(result => result.data);
}

export function createProduct(shopId, product) {
  return function(dispatch, getState) {
    debug(`Creating product ${product.name}`);
    firestore.createProduct(shopId, product)
      .then(product => {
        if(product) {
          dispatch(fetchShopProducts(shopId));
        }
      })
      .catch(error => {
        debug(`Could not create shop ${product.name}`, error);
        openStatusMessage('errors.could_not_create_product');
      });
  }
}

export function updateProduct(productId, product, merge=false) {
  return function(dispatch, getState) {
    debug(`Updating product ${productId}`);
    firestore.updateProduct(productId, product, merge)
      .then(product => {
        debug(`Updated product ${productId}`, product);
      })
      .catch(error => {
        debug(`Could not update product ${productId}`, error);
        openStatusMessage('errors.could_not_update_product');
      });
  }
}

export function refreshProductImageUrls(product) {
  return function(dispatch, getState) {
    debug(`refreshProductImageUrls`, product);
    
    if(product && product.images && product.images.length>0) {
      let images = [...product.images];
      for(let i=0; i<images.length; i++) {
        let image = images[i];
        if(image.deleted) continue;
        if(!image.thumb || !image.mid || !image.max) {
          getThumbUrl(image.filename).then( url => { 
            debug('Setting product image thumb url to', url);
            image.thumb = url;
          })
          .then( () => {
            getMidUrl(image.filename).then( url => { 
              debug('Setting product image mid url to', url);
              image.mid = url;
            })
            .then( () => {
              getMaxUrl(image.filename).then( url => { 
                debug('Setting product image max url to', url);
                image.max = url;
              })
              .then( () => {
                debug('Updating product with new image urls', images);
                dispatch(updateProduct(product.id, { images }, true));
              })
            })
          })
          .catch(e => debug("Couldn't get resized url for product", e));
        }
      }
    } else {
      debug(`No images found for product ${(product && product.id) || "null"}`);
    }
  }
}

export function refreshShopLogoUrls(shop) {
  return function(dispatch, getState) {
    
    if(shop && shop.logo && shop.logo.filename) {
      let image = {...shop.logo};
      debug(`refreshShopLogoUrls with logo`, image);
      if(!image.thumb || !image.mid || !image.max) {
        getThumbUrl(image.filename).then( url => { 
          debug('Setting shop logo thumb url to', url);
          image.thumb = url;
        })
        .then( () => {
          getMidUrl(image.filename).then( url => { 
            debug('Setting shop logo mid url to', url);
            image.mid = url;
          })
          .then( () => {
            getMaxUrl(image.filename).then( url => { 
              debug('Setting shop logo max url to', url);
              image.max = url;
            })
            .then( () => {
              debug('Updating shop with new logo urls', image);
              dispatch(updateShop(shop.id, { logo: image }, true));
            })

          })

        })
        .catch(e => debug("Couldn't get resized urls for shop logo", e));
      }
    } else {
      debug("No images found for shop", shop ? shop.id : "null shop");
    }
  }
}

export function fetchShopProducts(shopId, tag) {
  return function(dispatch, getState) {
    debug(`Fetching products from shop ${shopId}`);
    firestore.fetchShopProducts(shopId, tag)
      .then(products => dispatch(receiveProducts(products)) );
  }
}

export function fetchShopProductsByHandle(handle, tag) {
  return function(dispatch, getState) {
    debug(`Fetching products by shop handle ${handle}, tag ${tag}`);
    firestore.fetchShopProductsByHandle(handle, tag)
      .then(products => dispatch(receiveProducts(products)) );
  }
}

export function fetchProducts(productIds) {
  return function(dispatch, getState) {
    debug(`Fetching products by id`, productIds);
    firestore.fetchProducts(productIds)
      .then(products => dispatch( receiveProducts(products) ) );
  }
}

export function fetchProduct(productId) {
  return function(dispatch, getState) {
    debug(`Fetching product`, productId);
    firestore.fetchProduct(productId)
      .then(product => dispatch( receiveProducts({[product.id] : product}) ) );
  }
}

export function createPaymentLink(shopId, paymentLink) {
  return function(dispatch, getState) {
    debug(`Creating paymentLink ${paymentLink.name}`);
    firestore.createPaymentLink(shopId, paymentLink)
      .then(paymentLink => {
        if(paymentLink) {
          dispatch(fetchShopPaymentLinks(shopId));
        }
      })
      .catch(error => {
        debug(`Could not create shop ${paymentLink.name}`, error);
        openStatusMessage('errors.could_not_create_paymentLink');
      });
  }
}

export function updatePaymentLink(paymentLinkId, paymentLink, merge=false) {
  return function(dispatch, getState) {
    debug(`Updating paymentLink ${paymentLinkId}`);
    firestore.updatePaymentLink(paymentLinkId, paymentLink, merge)
      .then(paymentLink => {
        debug(`Updated paymentLink ${paymentLinkId}`, paymentLink);
      })
      .catch(error => {
        debug(`Could not update paymentLink ${paymentLinkId}`, error);
        openStatusMessage('errors.could_not_update_paymentLink');
      });
  }
}

export function fetchPaymentLink(paymentLinkId) {
  return function(dispatch, getState) {
    debug(`Fetching paymentLink`, paymentLinkId);
    firestore.fetchPaymentLink(paymentLinkId)
      .then(paymentLink => dispatch( receivePaymentLinks({[paymentLink.id] : paymentLink}) ) );
  }
}

export function fetchShopPaymentLinks(shopId) {
  return function(dispatch, getState) {
    debug(`Fetching paymentLinks from shop ${shopId}`);
    firestore.fetchShopPaymentLinks(shopId)
      .then(paymentLinks => dispatch(receivePaymentLinks(paymentLinks)) );
  }
}

export function fetchShopPaymentLinksByHandle(handle, tag) {
  return function(dispatch, getState) {
    debug(`Fetching paymentLinks by shop handle ${handle}, tag ${tag}`);
    firestore.fetchShopPaymentLinksByHandle(handle, tag)
      .then(paymentLinks => dispatch(receivePaymentLinks(paymentLinks)) );
  }
}

export function addToCart(product, quantity=1) {
  return function(dispatch, getState) {
    debug(`Adding product to cart`, product);
    let productId = product.id;
    if(product) {
      firestore.fetchShopById(product.shopId)
        .then( shop => {
          debug(`Adding product to cart`, product);
          let cart = localstore.addToCart(getState().cart, productId, product, shop, quantity);
          localstore.saveCart(cart);
          cart.shops[product.shopId].products[productId].checked = true;
          dispatch(receiveCart(cart));
          dispatch(openStatusMessage('cart.product_added'));
        })
        .catch( error => {
          debug(`Could not add to cart, firestore.fetchShopById failed`, error);
          dispatch(openStatusMessage('errors.could_not_add_to_cart'));
        });
    } else {
      debug(`Product ${productId} not found`);
      dispatch(openStatusMessage('errors.could_not_add_to_cart'));
    }
  }
}

export function changeCartQuantity(productId, quantity) {
  return function(dispatch, getState) {
    debug(`Changing quantity of product ${productId} on cart ${quantity}`);
    let cart = getState().cart;
    if(cart && cart.shops) {
      for(let shopId in cart.shops) {
        if(cart.shops[shopId] && cart.shops[shopId].products && cart.shops[shopId].products[productId]) {
          cart.shops[shopId].products[productId].quantity = quantity;
          cart.shops[shopId].products[productId].checked = (quantity>0);
          break;
        }
      }
      dispatch(receiveCart(cart));
      localstore.saveCart(cart);
    } else {
      debug("Empty cart, can't change product quantity");
    }
  }
}

export function checkCartProduct(shopId, productId, isChecked) {
  return function(dispatch, getState) {
    debug(`Checking ${isChecked} product ${productId} on cart for shop ${shopId}`);
    let cart = getState().cart;
    if(cart && cart.shops) {
      if(cart.shops[shopId]) {
        if(cart.shops[shopId] && cart.shops[shopId].products && cart.shops[shopId].products[productId]) {
          cart.shops[shopId].products[productId].checked = isChecked;
          dispatch(receiveCart(cart));
          // localstore.saveCart(cart);
        } else {
          debug(`No product ${productId} to check in cart`);
        }
      } else {
        debug(`No shop ${shopId} in cart to check product`);
      }
    } else {
      debug("Empty cart, can't check product");
    }
  }
}

export function clearCart(shopId) {
  return function(dispatch, getState) {
    debug(`Clear cart for shop ${shopId}`);
    let cart = getState().cart;
    if(cart && cart.shops && cart.shops[shopId]) {
      for(let productId in cart.shops[shopId].products) {
        cart.shops[shopId].products[productId].quantity = 0;
        cart.shops[shopId].products[productId].checked = false;
      }
      dispatch(receiveCart(cart));
      localstore.saveCart(cart);
    } else {
      debug("Can't clear empty cart");
    }
  }
}

export function fetchCart() {
  return function(dispatch, getState) {
    // firestore.fetchCart().then( cart => dispatch(receiveCart(cart)) );
    localstore.loadCart().then( cart => dispatch(receiveCart(cart)) );
  }
}

let fetchingStripeSession = false;

export function fetchStripeSession(order, shop) {
  return function(dispatch, getState) {
    const state = getState();
    const user = state.user;
    const shopId = shop.id;

    if(fetchingStripeSession) {
      debug("fetchStripeSession was already invoked, do nothing");
    } else {
      fetchingStripeSession = true;
      debug(`fetchStripeSession with order from shop ${shopId}`, order);
      let params = {
        order, 
        userId: user ? user.uid : null, 
        profile: state.profile,
        shop: shop,
        shippingCountries: shop.shippingCountries || [shop.country || "US"],
      };
      firestore.createOrder(shopId, order)
        .then( savedOrder => {
          params.orderId = savedOrder.id;
          stripeCheckoutSession(params)
            .then(result => {
              debug('fetchStripeSession received from Stripe:', result.data);
              fetchingStripeSession = false;
              dispatch(receiveStripeSession(result.data.checkoutSessionId));
            })
            .catch(error => {
              debug('Could not fetch Stripe checkout session id', error);
              fetchingStripeSession = false;
            });

        })
        .catch(error => {
          debug('Could not create order', error);
          fetchingStripeSession = false;
        });
    }
  }
}

export function fetchStripeInstashopSession(shopId, order, plan) {
  return function(dispatch, getState) {
    const state = getState();
    const user = state.user;

    if(fetchingStripeSession) {
      debug("fetchStripeSession was already invoked, do nothing");
    } else {
      fetchingStripeSession = true;
      debug(`fetchStripeInstashopSession on shop ${shopId} with order`, order);
      let params = {
        order, 
        shopId: shopId,
        userId: user.uid, 
        profile: state.profile,
        plan: plan,
      };
      firestore.createPlan(shopId, order)
        .then( savedPlan => {
          params.planId = savedPlan.id;
          stripeInstashopCheckoutSession(params)
            .then(result => {
              debug('fetchStripeInstashopSession received from Stripe:', result.data);
              fetchingStripeSession = false;
              dispatch(receiveStripeSession(result.data.checkoutSessionId));
            })
            .catch(error => {
              debug('Could not fetch Stripe checkout session id', error);
              fetchingStripeSession = false;
            });

        })
        .catch(error => {
          debug('Could not create plan', error);
          fetchingStripeSession = false;
        });
    }
  }
}


export function fetchOrder(orderId) {
  return firestore.fetchOrder(orderId);
}

export function fetchFulfilmentOrders(shopId) {
  return firestore.fetchFulfilmentOrders(shopId);
}

export function changeOrderStatus(orderId, status, history) {
  return function(dispatch, getState) {
    firestore.changeOrderStatus(orderId, status, history);
    // TODO: should notify order recipient
  }
}

export function getStripeAccountLink(accountId, update=false) {
  return function(dispatch, getState) {
    debug('getStripeAccountLink invoked with accountId', accountId);
    stripeCreateAccountLink({accountId, update})
      .then(result => {
        debug("getStripeAccountLink returned", result);
        dispatch(receiveStripeAccountLink(result.data.url));
      })
      .catch(error => {
        debug("Could not get Stripe account link", error);
      });
  }
}

export function getStripeLoginLink(accountId) {
  return function(dispatch, getState) {
    debug('getStripeLoginLink invoked with accountId', accountId);
    stripeCreateLoginLink({accountId})
      .then(result => {
        debug("getStripeLoginLink returned", result);
        dispatch(receiveStripeLoginLink(result.data.url));
      })
      .catch(error => {
        debug("Could not get Stripe login link", error);
      });
  }
}

export function createStripeAccount() {
  return function(dispatch, getState) {
    const profile = getState().profile;
    if(profile) {
      if(profile.stripeAccountId) {
        debug("Not creating Stripe account because already existing", profile.stripeAccountId);
        stripeGetAccount({accountId: profile.stripeAccountId})
          .then(result => {
            debug("stripeGetAccount returning account", result);
            if(result.data && result.data.id) {
              dispatch(receiveStripeAccount(result.data));
            }
          })
          .catch(error => {
            debug("Could not load stripe account", profile.stripeAccountId, error);
          });
      } else {
        debug("Creating Stripe account");
        stripeCreateAccount().then(result => {
          debug("stripeCreateAccount returning account", result);
          if(result.data && result.data.id) {
            dispatch(updateProfile({ stripeAccountId: result.data.id }));
            dispatch(receiveStripeAccount(result.data));
          }
        });
      }
    } else {
      debug("Can't create Stripe account because no user profile");
    }

  }
}

function appendFilename(filename, suffix) {
  let thumb;
  let i = filename.lastIndexOf('.');
  if(i<0) thumb = filename + suffix;
  else {
    thumb = "upload/" + filename.slice(0,i) + suffix + filename.slice(i);
  }
  return thumb;
}

function getThumbFilename(filename) {
  return appendFilename(filename, "_100x100");
}
function getMidFilename(filename) {
  return appendFilename(filename, "_200x200");
}
function getMaxFilename(filename) {
  return appendFilename(filename, "_400x400");
}

export function getThumbUrl(filename) {
  if(filename) {
    debug('getThumbUrl for filename', filename);
    return firebase.storage().ref(getThumbFilename(filename)).getDownloadURL();
  } else {
    debug('getThumbUrl invoked with null filename');
  }
}

export function getMidUrl(filename) {
  return firebase.storage().ref(getMidFilename(filename)).getDownloadURL();
}

export function getMaxUrl(filename) {
  return firebase.storage().ref(getMaxFilename(filename)).getDownloadURL();
}
