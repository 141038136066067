import React, { useEffect, useState } from 'react';
import { fetchFulfilmentOrders } from '../store/actions';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import OrderPreview from './OrderPreview';

function OrderList(props) {
  const [orders, setOrders] = useState({});
  const { t, i18n } = useTranslation('app');

  useEffect(() => {
    fetchFulfilmentOrders(props.shopId)
      .then( orders => setOrders(orders) );
  }, [props.shopId]);

  let sortedOrders = [];
  if(orders) {
    sortedOrders = Object.entries(orders).sort((x, y) => y[1].createdAt - x[1].createdAt);
  }
  if(sortedOrders && sortedOrders.length>0)
    return (
      <div>
        <Typography>
          {props.shop.name}
        </Typography>
        <List>
          {sortedOrders.map( (x, i) => <OrderPreview key={`order-${i}`} order={x[1]} /> )}
        </List>
      </div>
    );

  return (
    <Typography variant="body2" color="textSecondary" style={{ marginBottom: 40 }}>
      {t('fulfilment.no_orders')}
    </Typography>
  );
}

export default OrderList;
