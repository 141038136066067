import React, { useState, useEffect } from 'react';
import Fade from '@material-ui/core/Fade';


function Cycle(props) {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const timer = setInterval(function(){
      setCounter(counter => counter+1);
    }, props.interval || 2000);
    return () => clearInterval(timer);
  }, []);


  let showChild = counter % props.children.length;

  return (
    props.children.map( (child, i) => (
      <Fade
          key={`cycle-child-${i}`}
          in={i===showChild}
          timeout={props.timeout || 250}
          style={{ display: i===showChild ? "block" : "none" }}
      >
        {child}
      </Fade>
    ))
  );
}

export default Cycle;
