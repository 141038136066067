import React from 'react';

function BottomButtons(props) {
  return (
    <div className="bottom">
      <div className="bottom-content">
        {props.children}
      </div>
    </div>
  );
}

export default BottomButtons;
