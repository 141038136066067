import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { 
  isHandleAvailable, fetchOwnShops, createPaymentLink, updatePaymentLink, fetchPaymentLink,
  setPage,
} from '../store/actions';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';

import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { ButtonLink, ScrollToTop, debug, currencies, getCurrency, getCurrencyName, } from  '../utils/utils';
import UploadingFile from './UploadingFile';

import ImageCaptureButton from '../utils/ImageCaptureButton';
import BottomButtons from '../nav/BottomButtons';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

const PRIMARY_COLOR = "#402020";
const SECONDARY_COLOR = "#c0a0a0";

const useStyles = makeStyles({
  form: {
    paddingBottom: 52,
  },
  textfield: {
    minWidth: 300,
  },
  formControl: {
    marginTop: 24,
  },
  submitBtn: {
    position: "relative",
    top: 24,
  },
  formGroup: {
    marginTop: 24,
  },
  colorSwab: {
    width: 48,
    height: 40,
    margin: "8px 16px 0px 0px",
  },
  paymentLinkImages: {
    display: "flex",
    marginTop: 24,
  },
  paymentLinkImageContainer: {
    width: 200,
    height: 200,
    marginRight: 16,
    position: "relative",
  },
  paymentLinkImage: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  uploadingFiles: {
    marginTop: 24,
  },
  imageDeleteBtn: {
    position: "absolute",
    top: 0,
    right: 0,
  },
});

function EditPaymentLink(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');
  const [published, setPublished] = useState(true);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [lastUpdate, setLastUpdate] = useState(null);
  const [currency, setCurrency] = useState(getCurrency() || "USD");
  const [price, setPrice] = useState("");

  useEffect(() => {
    if(props.paymentLinkId) {
      props.load(props.paymentLinkId, t('shop.edit_payment_link'));
    } else {
      props.load(null, t('shop.add_payment_link'));

    }
  }, []);

  useEffect(() => {
    if(props.paymentLink) {
      setPublished(!!props.paymentLink.published);
      setName(props.paymentLink.name);
      setDescription(props.paymentLink.description);
      setCurrency(props.paymentLink.currency);
      setPrice(props.paymentLink.price);
    }
  }, [props.paymentLink]);


  const handleSubmit = e => {
    e.preventDefault();
    props.save(props.shopId, props.paymentLinkId, {
      published, name, description,
      currency, price,
    });
    setPublished(true);
    setName("");
    setDescription("");
    setCurrency(getCurrency() || "USD");
    setPrice("");
    props.history.push(`/shops/${props.shopId}/edit`);
  }

  return (
    <div className="feed">
      <ScrollToTop />
      <Typography variant="body2" color="textSecondary">
        {t('shop.editpaymentlink_intro')}
      </Typography>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div className={classes.form}>

          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={published}
                onChange={ e => setPublished(e.target.checked) }
                name="published"
              />}
            label={t('shop.publish')}
          />
            

          <TextField
            label={t('common.name')}
            name="name"
            required={true}
            className={classes.textfield}
            value={name}
            onChange={ e => setName(e.target.value) }
            margin="normal"
            fullWidth
          />

          <TextField
            label={t('common.description')}
            name="description"
            className={classes.textfield}
            value={description}
            onChange={ e => setDescription(e.target.value) }
            margin="normal"
            multiline={true}
            rows="3"
            fullWidth
          />

          <FormGroup row>
            <FormControl style={{ marginTop: 16, marginRight: 16 }}>
              <InputLabel>{t('common.currency')}</InputLabel>
              <Select
                native
                name="currency"
                value={currency}
                onChange={ e => setCurrency(e.target.value) }
              >
                {currencies.map( (c, i) => (<option key={`${c}_${i}`} value={c}>{getCurrencyName(c)}</option>) )}
              </Select>
            </FormControl>
            <TextField
              label={t('common.price')}
              name="price"
              type="number"
              className={classes.textField}
              value={price}
              onChange={ e => setPrice(e.target.value) }
              margin="normal"
              InputProps={{
                startAdornment: <InputAdornment position="start">{currency}</InputAdornment>
              }}
            />
          </FormGroup>

          <BottomButtons>
            <Button fullWidth variant="outlined" style={{marginRight: 16}}
              to={`/shops/${props.shopId}/edit`}
              component={ButtonLink}
            >
              {t('buttons.back')}
            </Button>

            <Button fullWidth variant="contained" color="primary" 
              type="submit"
            >
              {t('buttons.save')}
            </Button>

          </BottomButtons>

        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const shopId = (ownProps.match && ownProps.match.params) ? ownProps.match.params.shopId : null;
  let shop = null;
  if(shopId && state.ownShops && state.ownShops[shopId]) shop = state.ownShops[shopId];
  debug('shopId', shopId, 'shop', shop);

  const paymentLinkId = (ownProps.match && ownProps.match.params) ? ownProps.match.params.paymentLinkId : null;
  let paymentLink = null;
  if(paymentLinkId && state.paymentLinks && state.paymentLinks[paymentLinkId]) paymentLink = state.paymentLinks[paymentLinkId];
  debug('paymentLinkId', paymentLinkId, 'paymentLink', paymentLink);

  if(paymentLink && shop && paymentLink.shopId !== shopId) {
    debug(`EditPaymentLink, paymentLink ${paymentLinkId} is not in shop ${shopId}`);
    return {};
  }

  return {
    paymentLinkId,
    paymentLink,
    shopId,
    shop,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    save: (shopId, paymentLinkId, paymentLink, merge=false) => {
      if(paymentLinkId) dispatch(updatePaymentLink(paymentLinkId, paymentLink, merge));
      else dispatch(createPaymentLink(shopId, paymentLink));
    },
    load: (paymentLinkId, title, description) => {
      dispatch(setPage("edit_paymentLink", title, description));
      if(paymentLinkId) dispatch(fetchPaymentLink(paymentLinkId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPaymentLink);

