import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ShareIcon from '@material-ui/icons/Share';
import { whatsappLink } from '../utils/utils';

const useStyles = makeStyles({
  container: {
    margin: "16px 0px",
  },
});

function ShareButtons(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');

  let btnStyle = {
    marginRight: 16,
  };
  if(props.color) btnStyle.color = props.color;

  return (
    <div className={classes.container}>
      <Button aria-label="share"
          style={btnStyle}
          color="inherit"
          href={whatsappLink(t('shop.share_url', { name: props.name }), window.location.href)}
          startIcon={<ShareIcon />}>

        {t('buttons.share')}

      </Button>

      <Button aria-label="chat"
          style={btnStyle}
          color="inherit"
          href={whatsappLink(t('shop.hello', { name: props.name }), window.location.href, props.number)}
          startIcon={<WhatsAppIcon />}>

        {t('buttons.chat')}

      </Button>
    </div>
  );


}

export default ShareButtons;
