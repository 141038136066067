import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const useStyles = makeStyles({
  upload: {
    display: "flex",
    width: "100%",
  },
  filename: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  progressBar: {
    width: "100%",
    flexGrow: "1",
  }
});

export default function UploadingFile(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');
  const progress = props.file.done 
    ? <Typography variant="body2" color="textSecondary">{t('shop.upload_done')}</Typography>
    : (props.file.failed 
      ? <Typography variant="body2" color="textSecondary">{t('shop.upload_failed')}</Typography>
      : <LinearProgress variant="determinate" value={props.file.progress} />
    );

  return (
      <div className={classes.upload}>
        <div style={{ marginRight: 8 }}>
          <AttachFileIcon />
        </div>
        <div className={classes.filename}>{props.file.name}</div>
        <div className={classes.progressBar}>
          {progress}
        </div>
      </div>
  );
}
