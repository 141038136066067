import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { addToCart, refreshProductImageUrls } from '../store/actions';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { debug } from '../utils/utils';

const useStyles = makeStyles({
  product: {
    marginBottom: 8,
    backgroundColor: "var(--light-background-color)",
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
      maxWidth: "46%",
      '&:first-child': {
        marginRight: 16,
      }
    },
  },
  productImageContainer: {
    marginBottom: 8,
  },
  productImage: {
    display: "block",
    width: "100%",
    maxHeight: "400px",
    objectFit: "cover",
  },
  productName: {
    marginTop: "auto",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "var(--text-color)",
  },
  productPrice: {
    color: "var(--mui-primary-color)",
    marginTop: 4,
  },
  productBelow: {
    display: "flex",
    padding: 8,
  },
  productBelowLeft: {
    flexGrow: 1,
  },
  productBelowRight: {
    flexGrow: 0,
  },
});

function CatalogProductPreview(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');

  useEffect(() => {
    if(props.product && props.product.images && props.product.images.length>0) props.refresh(props.product);
  }, [props.product]);

  let img = null;
  if(!props.product) return <Skeleton variant="rect" height="200" />;

  let images = props.product ? props.product.images : null;
  images = images.filter(x => !x.deleted);
  if(images && images.length>0) {
    img = images[0];
  }
  debug(`CatalogProductPreview ${props.product.name} preview image`, img);

  return (
    <div className={classes.product}>
      <div className={classes.productImageContainer}>
        <Link to={`/products/${props.product.id}`} className="clean-link">
          {img && <img className={classes.productImage} src={img.max || img.url} />}
        </Link>
      </div>
      <div className={classes.productBelow}>
        <div className={classes.productBelowLeft}>
          <Link to={`/products/${props.product.id}`} className="clean-link">
            <div className={classes.productName}>{props.product.name}</div>
            <div className={classes.productPrice}>
              <NumberFormat
                value={props.product.price}
                prefix={props.product.currency + ' '}
                displayType={'text'}
                thousandSeparator={true} 
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </div>
          </Link>
        </div>
        <div className={classes.productBelowRight}>
          <Button color="default" variant="contained"
            onClick={e => props.addToCart(props.product)}
            startIcon={<AddShoppingCartIcon />}
          >
            {t('buttons.add')}
          </Button>

        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    shop: state.shop,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addToCart: product => {
      dispatch(addToCart(product));
    },
    refresh: (product) => {
      dispatch(refreshProductImageUrls(product));
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CatalogProductPreview);

