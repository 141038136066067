import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CameraAltIcon from '@material-ui/icons/CameraAlt';


const useStyles = makeStyles({
  leftIcon: { marginRight: 8 },
});

function ImageCaptureButton(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');

  return (
    <Button variant="contained" component="label">
      <CameraAltIcon className={classes.leftIcon} />
      {props.label || t('common.upload')}
      <input id="capture-image" 
        type="file" accept="image/*" multiple={props.multiple || false}
        style={{ display: 'none' }}
        onChange={props.handleFiles}
      />
    </Button>
  );
}


export default ImageCaptureButton;
