import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { firebaseLogout } from '../store/actions';

function InnerLogout(props) {
  useEffect(() => {
    props.logout();
  }, []);

  return <Redirect to="/" />;
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.isLoggedIn,
    isLoggingIn: state.isLoggingIn,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: () => dispatch(firebaseLogout()),
  }
}
const Logout = connect(mapStateToProps, mapDispatchToProps)(InnerLogout);

export default Logout;
