import React, { lazy } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import BottomButtons from '../nav/BottomButtons';
import { firebaseLogin } from '../store/actions';
import firebase from 'firebase/app';
import 'firebase/auth';
import { getCountry } from '../utils/utils';

const StyledFirebaseAuth = lazy(() => import('react-firebaseui/StyledFirebaseAuth'));
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';


function InnerLogin(props) {
  let query = null;
  let next = null;
  if(props.history && props.history.location) query = props.history.location.search;
  if(query && query.length>1) {
    let kvs = query.substring(1).split('&');
    for(let i=0; i<kvs.length; i++) {
      let kv = kvs[i].split('=');
      if(kv[0]==="next") {
        next = decodeURIComponent(kv[1]);
        break;
      }
    }
  }

  let country = getCountry();

  const uiConfig = {
    signInFlow: "redirect",
    signInSuccessUrl: props.next || next || '/',
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      /*
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        authMethod: 'https://accounts.google.com',
        clientId: '1045477241356-a79n4kfsv76t65ee0hrinfmt5i49f592.apps.googleusercontent.com',
      },*/
      //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      //firebase.auth.GithubAuthProvider.PROVIDER_ID,
      //firebase.auth.EmailAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        defaultCountry: country,
      }, 
      //firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
    ],
    // credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: 'https://instashop.bio/terms',
    // Privacy policy url/callback.
    privacyPolicyUrl: 'https://instahop.bio/privacy'
  };

  firebase.auth().useDeviceLanguage();


  return (
    <div className="feed">
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </div>
  );
}

export default InnerLogin;
