import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { setPage, changeOrderStatus } from "../store/actions";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { fetchOrder } from '../store/actions';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import moment from 'moment';
import NumberFormat from 'react-number-format';

import { ScrollToTop, debug, whatsappLink } from  '../utils/utils';
import CartProduct from '../home/CartProduct';

const useStyles = makeStyles({
  orderDetails: {
    marginTop: 24
  },
  chatBtn: {
    display: "inline-block",
    marginLeft: 48,
    marginTop: 16,
  },
});

function ViewOrder(props) {
  const classes = useStyles();
  const [order, setOrder] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [fulfil, setFulfil] = useState(false);

  const { t, i18n } = useTranslation('app');

  useEffect(() => {
    const orderId = (props.match && props.match.params) ? props.match.params.orderId : null;
    const landing = (props.match && props.match.params) ? props.match.params.landing : null;
    if(orderId) fetchOrder(orderId).then( order => setOrder(order) );
    switch(landing) {
      case "thankyou":
        setTitle(t('checkout.thankyou'));
        setDescription(t('checkout.thankyou_description'));
        break;
      case "cancel":
        setTitle(t('checkout.cancel'));
        setDescription(t('checkout.cancel_description'));
        break;
      case "fulfil":
        setTitle(t('order.fulfil'));
        setFulfil(true);
        break;

      default:
        setTitle(t('common.order'));

    }
  }, []);

  useEffect(() => {
    props.load(title, description);
  }, [order]);

  const changeStatus = e => {
    let newOrder = {...order};
    newOrder.status = e.target.value;
    setOrder(newOrder);
    props.saveStatus(newOrder.id, newOrder.status, newOrder.history);
  }

  let details = <Skeleton variant="text" />;
  let action = null;
  let shippingInfo = null;
  let history = null;
  let products = null;
  let payment = null;

  if(order) {
    details = (
      <span>
        {t("order.order_created", { id: order.id, date: moment.unix(order.createdAt.seconds).format('lll') })}
      </span>
    );
    if(order.payment) {
      payment = (
        <NumberFormat
          value={order.payment.amount_total/100.0}
          prefix={order.payment.currency.toUpperCase()}
          displayType={'text'}
          thousandSeparator={true} 
          decimalScale={2}
          fixedDecimalScale={true}
        />
      );
    }
    if(fulfil) {
      let userPhone = order ? order.userPhone : null;
      let shopHandle = order && order.shop ? order.shop.handle : null;
      let shopUrl = shopHandle ?
        `https://instashop.bio/${shopHandle}` 
        : `https://instashop.bio/s/${order.shopId}`;
      let address = null;
      let phone = null;
      let recipient = null;
      if(order.payment) {
        if(order.payment.shipping) {
          recipient = order.payment.shipping.name;
          phone = order.payment.shipping.phone;
          const addr = order.payment.shipping.address;
          address = (
            <pre>
              {addr.line1} <br/>
              {addr.line2} <br/>
              {addr.city} {addr.postal_code} <br/>
              {addr.state} <br/>
              {addr.country}
            </pre>
          );
        }

      }

      action = (
        <div>
          <FormControl>
            <InputLabel>{t("order.order_status")}</InputLabel>
            <Select native value={order.status} onChange={changeStatus}>
              <option value="pending">{t("order.pending")}</option>
              <option value="paid">{t("order.paid")}</option>
              <option value="preparing">{t("order.preparing")}</option>
              <option value="shipped">{t("order.shipped")}</option>
              <option value="received">{t("order.received")}</option>
              <option value="canceled">{t("order.canceled")}</option>
            </Select>
          </FormControl>

          {userPhone &&
            <div className={classes.chatBtn}>
              <Button aria-label="chat"
                  color="inherit"
                  href={whatsappLink(t('common.hello', { name: recipient }), shopUrl, userPhone)}
                  startIcon={<WhatsAppIcon />}>

                {t('buttons.chat')}

              </Button>
            </div>
          }
        </div>
      );

      shippingInfo = (
        <React.Fragment>
          <Typography variant="h6">
            {t('order.ship_to')}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            <strong>{recipient}</strong>
            &nbsp;
            {phone && <a href={`tel:${phone}`}>{phone}</a>}
            <br/>

            {address}

          </Typography>
        </React.Fragment>
      );
    } else {
      action = (
        <Typography variant="body1" color="textSecondary">
          {t(`order.${order.status}`)}
        </Typography>
      );
    }
    if(order.history) {
      history = (
        <div>
          <Typography variant="h6">
            {t('order.history')}
          </Typography>
          {order.history.map( (x,i) => (
            <Typography variant="body2" color="textSecondary" key={`history-${i}`}>
              {moment.unix(x.timestamp).format('lll')} - {t(`order.${x.status}`)}
            </Typography>
          ))}
        </div>
      );
    }
    if(order.products) {
      products = Object.values(order.products)
        .map(
          (cartEntry, i) => {
            return(
              <CartProduct 
                key={`prod-${i}`} 
                cartEntry = {cartEntry}
                checkout
              /> 
            )
          }
        );
      }
  }

  return (
    <div className="feed">
      <Typography variant="body1" color="textSecondary" gutterBottom>
        {description}
      </Typography>

      <div className={classes.orderDetails}>
        <Typography gutterBottom>
          {details}
        </Typography>
        <Typography gutterBottom>
          <strong>{t('order.total_paid')}: {payment}</strong>
        </Typography>
      </div>
      <div className={classes.orderDetails}>
        {products}
      </div>
      <div className={classes.orderDetails}>
        {action}
      </div>
      <div className={classes.orderDetails}>
        {shippingInfo}
      </div>
      <div className={classes.orderDetails}>
        {history}
      </div>

    </div>
  );
}

const mapStateToProps = state => {
  return { };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: (title, description) => dispatch(setPage("order", title, description)),
    saveStatus: (orderId, status, history) => dispatch(changeOrderStatus(orderId, status, history)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrder);
