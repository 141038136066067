import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { updateSession, isHandleAvailable, setPage } from '../store/actions';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation, Trans } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { ButtonLink, CenteredCircularProgress, debug } from '../utils/utils';
import Cycle from '../utils/Cycle';

import Footer from '../nav/Footer';

const useStyles = makeStyles({
  center: {
    textAlign: "center",
  },
  appstore: {
    position: "relative",
    left: -10,
    marginTop: 16,
  },
  hero: {
    padding: "32px 0px 32px",
    marginBottom: 16, 
  },
  terms: {
    marginTop: 16,
  },
  block: {
    marginTop: 32,
  },
  uspContainer: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
      flexDirection: "row",
    },
  },
  usp: {
    marginTop: 8,
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
      maxWidth: "390px",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  uspImageContainer: {
    maxWidth: "100%",
    boxSizing: "border-box",
    padding: 16,
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
      maxWidth: "390px",
    }
  },
  uspImage: {
    width: "100%",
  },
  uspEven: {
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
      order: -1,
    }
  },
  form: {
    margin: "16px auto 0px",
    maxWidth: 500,
    textAlign: "left",
  },
  textfield: {
    minWidth: 300,
  },
  submitBtn: {
    /*
    position: "relative",
    top: 24,*/
    marginTop: 16,
    textAlign: "center",
  },
});

function InnerHomePage(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');
  const [validateHandle, setValidateHandle] = useState({ error: false, message: ""});

  useEffect(() => {
    props.load(t('home.home'));
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
  };

  const validate = () => {
    const slug = props.handle ? props.handle.trim() : null;

    if(!slug || slug.length==0) {
      /*
      setValidateHandle({ error: true, message: t('home.enter_url') });
      return false;
      */
      // just proceed to signup if empty
      return true;
    }

    if(slug.length<6 || slug.length>64) {
      setValidateHandle({ error: true, message: t('home.url_length_error')});
      return false;
    }
    const pattern = /^[a-zA-Z0-9_]+$/;
    if(!pattern.test(slug)) {
      setValidateHandle({ error: true, message: t('home.url_chars_error') });
      return false;
    }

    if(isHandleAvailable(slug)) {
      debug(`Handle ${slug} is available`);
      setValidateHandle({ error: false, message: ""});
    } else {
      debug(`Handle ${slug} is not available`);
      setValidateHandle({ error: true, message: t('home.url_taken_error', { url: slug }) });
    }

    return true;
  }

  const checkHandle = e => {
    const valid = validate();
    if(e && !valid) e.preventDefault();
  }

  const handleChange = e => {
    props.updateHandle(e.target.value);
    checkHandle();
  }

  const next = `/shops/new?shopid=${encodeURIComponent(props.handle)}`;

  return (
    <div className="feed">
      <div className={classes.center}>
        <div className={classes.hero}>
          <h1>
            {t('home.hero_title')}
          </h1>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {t('home.hero_subtitle')}
          </Typography>
        </div>

        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div className={classes.form}>
            <TextField
              label={t('home.shopid')}
              name="shopid"
              className={classes.textfield} 
              helperText={validateHandle.error ? validateHandle.message : t('home.shopid_helper')}
              value={props.handle}
              autoFocus={true}
              onChange={handleChange}
              onBlur={validate}
              margin="normal"
              fullWidth
              error={validateHandle.error}
              InputProps={{
                startAdornment: <InputAdornment position="start">instashop.bio/</InputAdornment>
              }}
            />
            <div className={classes.submitBtn}>
              <Button
                  component={ButtonLink} 
                  to={`/login?next=${encodeURIComponent(next)}`}
                  variant="contained" color="primary"
                  onClick={checkHandle}
                  fullWidth
                  style={{ margin: "0 auto" }}
              >
                {t('home.get_started')}
              </Button>

            </div>

            <Typography className={classes.terms} variant="body2" color="textSecondary" gutterBottom>
              {t('home.terms')}
            </Typography>

            <Typography className={classes.terms} variant="body2" color="textSecondary" gutterBottom>
              {t('home.terms_2')}
            </Typography>

            <div className={classes.appstore}>
              <a href="https://play.google.com/store/apps/details?id=bio.instashop.sellonline">
                <img alt="Get it on Google Play" height="60" src="/play_badge.png" />
              </a>
            </div>
          </div>
        </form>

        <div className={classes.uspContainer}>
          <div className={classes.uspImageContainer}>
            <Cycle interval={2000}>
              <img className={classes.uspImage} src="/screenshots/coffee_shop.png" />
              <img className={classes.uspImage} src="/screenshots/links_and_media.png" />
              <img className={classes.uspImage} src="/screenshots/flower_shop.png" />
            </Cycle>
          </div>
          <div className={classes.usp}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              {t('home.usp1_title')}
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {t('home.usp1_desc1')}
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {t('home.usp1_desc2')}
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {t('home.usp1_desc3')}
            </Typography>
          </div>
        </div>

        <div className={classes.uspContainer}>
          <div className={classes.uspImageContainer}>
            <Cycle interval={2200}>
              <img className={classes.uspImage} src="/screenshots/guitar_shop_2.png" />
              <img className={classes.uspImage} src="/screenshots/flowers.png" />
              <img className={classes.uspImage} src="/screenshots/guitar_2.png" />
            </Cycle>
          </div>
          <div className={`${classes.usp} ${classes.uspEven}`}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              {t('home.usp2_title')}
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {t('home.usp2_desc')}
            </Typography>
          </div>
        </div>

        <div className={classes.uspContainer}>
          <div className={classes.uspImageContainer}>
            <Cycle interval={1900}>
              <img className={classes.uspImage} src="/screenshots/checkout_service_fees.png" />
              <img className={classes.uspImage} src="/screenshots/cappuccino.png" />
              <img className={classes.uspImage} src="/screenshots/shopping_cart.png" />
              <img className={classes.uspImage} src="/screenshots/guitar_1.png" />
            </Cycle>
          </div>
          <div className={classes.usp}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              {t('home.usp3_title')}
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {t('home.usp3_desc1')}
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {t('home.usp3_desc2')}
            </Typography>
          </div>
        </div>
      </div>
    
      <div>
        <div className={classes.usp}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            {t('home.examples_title')}
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {t('home.examples_desc')}
          </Typography>
        </div>
        <div className={classes.usp}>
          <Link to="/cafeinated">instashop.bio/cafeinated</Link>
        </div>
        <div className={classes.usp}>
          <Link to="/flowershop">instashop.bio/flowershop</Link>
        </div>
        <div className={classes.usp}>
          <Link to="/guitarshop">instashop.bio/guitarshop</Link>
        </div>

      </div>

      <div>
        <div className={classes.block}>
          <Typography variant="h6" color="textPrimary" gutterBottom={true}>
            {t('home.pricing_title')}
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            <Trans i18nKey="home.pricing_desc_0">
    Your first <strong>Instashop.bio/yourname</strong> page is FREE. This includes your custom brand logo and colors, unlimited number of links, YouTube videos and Spotify tracks.
            </Trans>
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {t('home.pricing_desc_1')}
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            <Trans i18nKey="home.pricing_desc_2">
     Payment fees are <strong>2.9% + US$0.30</strong> in the US. For our other 40+ countries, please check payment fees <a href='https://stripe.com/pricing'>here</a>.
            </Trans>
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            <Trans i18nKey="home.pricing_desc_3">
     Platform transaction fees are <strong>2%</strong> for our basic free plan, or <strong>0%</strong> if you upgrade to our PRO plan at <strong>US$19/month</strong>.
            </Trans>
          </Typography>
        </div>
      </div>

      <div className={classes.center}>
        <Button
            component={ButtonLink} 
            to={`/login?next=${encodeURIComponent('/shops/new')}`}
            variant="contained" color="primary"
            size="large"
        >
          {t('home.get_started')}
        </Button>
      </div>

      <Footer />
    </div>
  );

}

const mapStateToProps = (state, ownProps) => {
  return {
    handle: state.session.handle || "",
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateHandle: handle => {
      dispatch(updateSession({handle: handle}));
    },
    load: (title, description) => {
      dispatch(setPage("homepage", title, description));
    },
  }
}

const HomePage = connect(mapStateToProps, mapDispatchToProps)(InnerHomePage);
export default HomePage;

