import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { truncate } from '../utils/utils';

function LinkPreview(props) {
  return (
    <ListItem divider>
      <ListItemText primary={props.link.name} secondary={truncate(props.link.url)}/>

      <ListItemSecondaryAction>
        <IconButton aria-label="delete" 
          onClick={props.deleteLink}
        >
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default LinkPreview;
