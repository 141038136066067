import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import {
  setPage, validateHandle, fetchShopByHandle, fetchShopById, fetchOwnShops,
  fetchShopProductsByHandle, fetchShopProducts,
  refreshShopLogoUrls, 
} from '../store/actions';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ShareButtons from './ShareButtons';
import Catalog from './Catalog';
import ViewLinks from './ViewLinks';
import ActivationAlert from './ActivationAlert';
import { ButtonLink } from '../utils/utils';
import { ScrollToTop, debug, getForegroundColor } from  '../utils/utils';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles({
  header: {
    /*
    display: "flex",
    flexDirection: "column",
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
      flexDirection: "row",
    } */
  },
  shopBackground: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "45vh",
    zIndex: -101,
  },
  shopDiagonalBackground: {
    width: "100%",
    height: "55vh",
    transform: "skewY(-5deg)",
    top: 0,
    position: "absolute",
    zIndex: -100,
  },
  shopContainer: {
    // position: "relative",
    // top: "-72vh",
    // padding: 8,
    /*
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
      padding: 24,
    },*/
    // backgroundColor: "var(--primary-background-color)",
    // borderRadius: 3,
    // boxShadow: "1px 1px 5px grey",
    minHeight: "92vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  shopLogo: {
    // minWidth: 200,
    maxHeight: 400,
    marginBottom: 16,
    textAlign: "center",
  },
  inactive: {
    marginTop: 48,
    padding: 16,
    textAlign: "center",
  },
  logoImage: {
    maxWidth: "100%",
    // maxHeight: "100%",
    maxHeight: 400,
    margin: "0px auto",
    height: "auto",
    width: "auto",
  },
  shopIntro: {
    flexGrow: 1,
  },
  shopTitle: {
    marginTop: 0,
    textAlign: "center",
  },
  shopHandle: {
    marginTop: 0,
    fontWeight: 400,
    textSize: 20,
  },
  credits: {
    paddingTop: 80,
    fontSize: 16,
    textAlign: "center",
  }

});

const InstashopButton = withStyles( theme => ({
  root: {
    fontFamily: '"Lora", -apple-system, Arial, Helvetica, sans-serif',
    textTransform: "none",
    fontSize: 16,
  },
}))(Button);

function ViewShop(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');
  var previousBackgroundColor = null;
  var previousForegroundColor = null;
  var foregroundColor = null;

  useEffect(() => {
    if(props.normHandle) props.load(null, props.normHandle, props.tag);
    else if(props.shopId) props.load(props.shopId, null, props.tag);
  }, [props.normHandle, props.shopId, props.tag]);

  useEffect(() => {
    let fg1 = getComputedStyle(document.querySelector(":root")).getPropertyValue("--text-color");
    let fg2 = getComputedStyle(document.querySelector(":root")).getPropertyValue("--dark-theme-text-color");
    if(props.shop) {
      if(props.shop.logo) props.refresh(props.shop);
      if(props.shop.primaryColor) {
        previousBackgroundColor = getComputedStyle(document.body).backgroundColor;
        previousForegroundColor = getComputedStyle(document.body).color;
        // debug(`Changing background color; previous color ${previousBackgroundColor}`);
        document.body.style.backgroundColor = props.shop.primaryColor;
        document.getElementById("nav").style.backgroundColor = props.shop.primaryColor;
        foregroundColor = getForegroundColor(props.shop.primaryColor, fg1, fg2);
        document.body.style.color = foregroundColor;
      }
    }
    return function cleanup() {
      // debug(`cleanup: returning to color ${previousBackgroundColor}`);
      if (previousBackgroundColor) {
        document.body.style.backgroundColor = previousBackgroundColor;
        document.getElementById("nav").style.backgroundColor = previousBackgroundColor;
      }
      if (previousForegroundColor) document.body.style.color = previousForegroundColor;
    }
  }, [props.shop]);

  let logo;
  let head = null;
  let header = <Skeleton variant="rect" height={200} />;
  let shopStyle = {};
  let shopDiagonalStyle = {};
  let products = null;
  if(props.products) products = Object.values(props.products);

  let productsTitle = props.tag ? t('shop.tag_products', {tag: props.tag}) : t('shop.all_products');

  if(props.shop) {
    const json_ld = {
      "@context": "https://schema.org",
       "@type": "Organization",
       "name": props.shop.name,
       "url": window.location.href,
       "logo": props.shop.logo ? props.shop.logo.max || props.shop.logo.url : null,
       "address": {
         "@type": "PostalAddress",
         "addressCountry": props.shop.country,
         "streetAddress": props.shop.address,
       },
    };

    head = (
      <Helmet>
        <title>{`${props.shop.name} | instashop.bio`}</title>
        <meta name="description" content={props.shop.description} />
        <script type="application/ld+json">
          {JSON.stringify(json_ld)}
        </script>
      </Helmet>
    );

    if(props.shop.logo && props.shop.logo.url) logo = (
      <img className={classes.logoImage}
        src={props.shop.logo.max || props.shop.logo.url} 
        alt={`${props.shop.handle}'s shop image`}
      />
    ); 
    if(props.shop.primaryColor) {
      shopStyle["backgroundColor"] = props.shop.primaryColor;
      shopDiagonalStyle["backgroundImage"] = `linear-gradient(0deg, ${props.shop.primaryColor}, var(--primary-background-color) 70%)`;
    }
    if(props.shop.plan === 'pending') {
      header = (
        <header className={classes.header}>
          <section className={classes.shopIntro}>
            <div className={classes.inactive}>
              {props.canEdit
                ? <ActivationAlert shopId={props.shop.id} />
                : <Typography variant="body2" gutterBottom>
                    {t('shop.inactive_description')}
                  </Typography>}
            </div>
          </section>
        </header>
      );
    } else {
      header = (
        <header className={classes.header}>
          <section className={classes.shopIntro}>
            <div className={classes.shopLogo}>
              {logo}
            </div>

            <ShareButtons name={props.shop.name} number={props.shop.number} color={foregroundColor}/>

            <Typography variant="body1" gutterBottom>
              {props.shop.description}
            </Typography>
          </section>

          <section className={classes.links}>
            <ViewLinks links={props.shop.links} backgroundColor={props.shop.secondaryColor || props.shop.primaryColor} />
          </section>
        </header>
      );
    }
  } else {
    if(props.shopNotFound) {
      return(
        <div className="feed">
          <div className={classes.shopContainer}>
            <ScrollToTop />
            <div>
              <h1 className={classes.shopTitle}>
                {t('shop.not_found')}
              </h1>
              <Typography color="textSecondary" gutterBottom>
                {t('shop.not_found_description', { name: props.handle })}
                &nbsp;
                <Link to="/">{t('buttons.go_home')}</Link>
              </Typography>
            </div>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      {/*
      <div className={classes.shopDiagonalBackground} style={shopDiagonalStyle}>
      </div>
      <div className={classes.shopBackground} style={shopStyle}>
      </div>*/}
      {head}
      <div className="feed">
    
        <div className={classes.shopContainer}>
          <ScrollToTop />
          <div>
            <h1 className={classes.shopTitle}>
              {props.shop ? props.shop.name : <Skeleton variant="text" />}
              &nbsp;
              {props.canEdit && 
                <Button aria-label="edit" 
                  component={ButtonLink}
                  color="inherit"
                  variant="outlined"
                  to={`/shops/${props.shop.id}/edit`}
                  startIcon={<EditIcon />}
                >
                  {t('buttons.edit')}
                </Button>
              }
            </h1>

            {header}

            {products && products.length>0 && <h3>{productsTitle}</h3>}

            <Catalog products={products} />
          </div>

          {props.shop && props.shop.plan!=='pro' &&
            <div className={classes.credits}>
              <InstashopButton 
                component={ButtonLink}
                to="/"
                startIcon={<AddShoppingCartIcon />}
                size="small"
              >
                Instashop.bio
              </InstashopButton>
              {props.canEdit && 
                <span className="small">
                  (<Link to={`/shops/${props.shop.id}/pay`}>
                    {t('plans.upgrade')}
                  </Link>)
                </span>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );

}

const mapStateToProps = (state, ownProps) => {
  const shopId = (ownProps.match && ownProps.match.params) ? ownProps.match.params.shopId : null;
  const handle = (ownProps.match && ownProps.match.params) ? ownProps.match.params.handle : null;
  const tag = (ownProps.match && ownProps.match.params) ? ownProps.match.params.tag : null;
  let normHandle = null;
  let shop = state.shop;
  try {
    if(handle && handle.length>0) normHandle = validateHandle(handle);
  } catch(err) {
    debug(`Could not load shop ${handle} due to exception`, err);
  }
  let canEdit = false;
  if(shop && state.ownShops) {
    if(state.ownShops[shop.id]) canEdit = true;
  }
  return {
    handle,
    normHandle,
    shopId,
    tag,
    shop,
    canEdit,
    shopNotFound: state.shopNotFound,
    products: state.products,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: (shopId, handle, tag, title, description) => {
      dispatch(setPage("shop", title, description));
      if(handle) {
        dispatch(fetchShopByHandle(handle));
        dispatch(fetchShopProductsByHandle(handle, tag));
      }
      else if(shopId) {
        dispatch(fetchShopById(shopId));
        dispatch(fetchShopProducts(handle, tag));
      }
    },
    refresh: (shop) => {
      dispatch(refreshShopLogoUrls(shop));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewShop);
