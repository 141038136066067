import React from 'react';
import { useEffect } from "react";
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import debugLogger from 'debug';
import country_currency from './currency.json';
import currency_names from './currency_names.json';

debugLogger.enable('instashopbio');

export var STRIPE_PUBLIC_KEY = 'pk_live_51HeHmZH5i0pvCccBFmtHHLR5T4kJ74n4ClMcvAVmgLi1tk6Nhkhw1GBgj6Q1PZjwVeZFjaeq3QJHSxHie6mg0SLQ00yiEsu5o7';
export var ADDITIONAL_SHOP_PRODUCT_ID = 'prod_IbzeGNzd6bFKhq';
export var ADDITIONAL_SHOP_PRICE_ID = 'price_1I0lfGH5i0pvCccBvxaGuHG1';
// export var PRO_SHOP_PRICE_ID   = 'price_1I1mLdH5i0pvCccBEoR05VSm'; old $29/month price
export var PRO_SHOP_PRICE_ID   = 'price_1I2X2YH5i0pvCccBfktOilbP';
export var PRO_SHOP_PRODUCT_ID = 'prod_Id2Q1AcBVGfBLh';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  STRIPE_PUBLIC_KEY = 'pk_test_51HeHmZH5i0pvCccBwmEsRrJuQjexMivYnvTwoL3mVYcS3piAv6xZAnWWEdlYarLZUGXwvhbDQEtXFMNj054VYhNs00uAl26wqr';

  ADDITIONAL_SHOP_PRICE_ID = 'price_1HzG9vH5i0pvCccB6FlolIwB';
  ADDITIONAL_SHOP_PRODUCT_ID = 'prod_IaR1S0i73vy8mf';

  PRO_SHOP_PRICE_ID   = 'price_1I1mKMH5i0pvCccBkaPKK8gT';
  PRO_SHOP_PRODUCT_ID = 'prod_Id2PNLyrfUsJXn';
}
export const debug = debugLogger('instashopbio');

export const currencies = [
  "USD", "CAD", "EUR", "CHF", "GBP", "SEK", "NOK", "JPY", "SGD", "HKD", "KRW", "MYR", "AUD", "NZD",
];

export function findInObject(array, key, value) {
  for(var i=0; i<array.length; i++) {
    if((key in array[i] && array[i][key]===value)
      || (array[i].metadata && key in array[i].metadata && array[i].metadata[key]===value)) {
      return i;
    }
  }
  return -1;
}

export function getCountry() {
  let locale = null;
  let country = null;
  if(navigator && navigator.languages && navigator.languages.length>0) {
    locale = navigator.languages[0].split('-');
  } else {
    locale = navigator.language.split('-');
  }
  if(locale && locale.length>1) country = locale[1].toUpperCase();
  return country;
}

export function getCurrency(country=null) {
  if(!country) {
    country = getCountry();
  }
  if(country && country.toUpperCase() in country_currency) return country_currency[country.toUpperCase()];
  return null;
}

export function getCurrencyName(currencyCode) {
  let i = findInObject(currency_names, "cc", currencyCode);
  if(i>=0) return currency_names[i].name;
  return currencyCode;
}

export function CenteredCircularProgress(props) {
  return (
    <div style={{textAlign: "center", marginTop: 40, marginBottom: 40}}>
      <CircularProgress />
    </div>
  );
}

/** Return normalized color tuple given hex css color string */
function sRGB(color) {
  // debug(`Converting color ${color} to sRGB`);
  var m = color.trim().match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
  return {
      rs: parseInt(m[1], 16) / 255.0,
      gs: parseInt(m[2], 16) / 255.0,
      bs: parseInt(m[3], 16) / 255.0,
  };
}

function getRelativeLuminance(color) {
  // See https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
  let { rs, gs, bs } = sRGB(color);
  // debug(`Converted color ${color} to RGB ${rs} ${gs} ${bs}`);
  let R = (rs <= 0.03928) ? (rs/12.92) : ((rs+0.055)/1.055)**2.4;
  let G = (gs <= 0.03928) ? (gs/12.92) : ((gs+0.055)/1.055)**2.4;
  let B = (bs <= 0.03928) ? (bs/12.92) : ((bs+0.055)/1.055)**2.4;
  let luminance = 0.2126 * R + 0.7152 * G + 0.0722 * B;
  // debug(`Found luminance of ${luminance} for ${R} ${G} ${B}`);
  return luminance;
}

function getContrastRatio(l1, l2) {
  // See https://ux.stackexchange.com/questions/114952/determine-the-best-text-color-for-a-given-background-color
  // debug(`Calculating contrast for ${l1} and ${l2}`);
  let llight = l1 > l2 ? l1 : l2;
  let ldark  = l1 > l2 ? l2 : l1;
  return (llight+0.05)/(ldark+0.05);
}

export function getForegroundColor(bgColor, fg1, fg2) {
  try {
    let contrast1 = getContrastRatio(getRelativeLuminance(bgColor), getRelativeLuminance(fg1));
    let contrast2 = getContrastRatio(getRelativeLuminance(bgColor), getRelativeLuminance(fg2));
    // debug(`Picking between ${fg1} (${contrast1}) and ${fg2} (${contrast2})`);
    return contrast1>contrast2 ? fg1 : fg2;
  } catch(err) {
    debug(`Could not pick foregroundColor for bg ${bgColor} between ${fg1} and ${fg2}`);
    return fg1;
  }
}

export const ButtonLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);
export const whatsappLink = (text, url=null, recipient=null) => {
  let wa, watext;
  if(recipient && recipient.length>0) {
    let regex = /[\D]/;
    wa = (`https://wa.me/${recipient.replace(regex, '')}`)
  } else {
    wa = 'https://wa.me/';
  }
  if(text && url) watext = `${text} ${url}`;
  else {
    if(text) watext = text;
    if(url) watext = url;
  }
  if(watext) return (`${wa}?text=${encodeURIComponent(watext)}`)
  return wa;
};

export function ScrollToTop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

export function truncate(text, max=64) {
  if(!text) return null;
  if(text.length < max) return text;

  // truncate to max characters
  let trimmed = text.substring(0, max);
  // cut any last word
  let lastSpace = trimmed.lastIndexOf(" ");
  if(lastSpace>0)
   trimmed = trimmed.substring(0, lastSpace);
  trimmed = trimmed + "...";
  return trimmed;
}


