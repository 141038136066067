import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { fetchOwnShops, createStripeAccount, getStripeAccountLink, getStripeLoginLink,
  setPage, updateProfile,
} from '../store/actions';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import TimelineIcon from '@material-ui/icons/Timeline';
import Skeleton from '@material-ui/lab/Skeleton';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import ShopPreview from '../shop/ShopPreview';
import BottomNavBar from '../nav/BottomNavBar';
import { ButtonLink, debug } from '../utils/utils';

const useStyles = makeStyles({
  addBtn: {
    margin: "16px 0px 0px",
  },
  rowInput: {
    display: "flex",
  },
  rowBtn: {
    marginLeft: 8,
    marginTop: 28,
  }
});

function InnerDashboard(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');
  const [email, setEmail] = useState("");

  useEffect(() => {
    props.loadDashboard(t('nav.dashboard'));
  }, []);

  useEffect(() => {
    if(!props.stripeAccount) props.loadStripeAccount();
  }, [props.profile]);

  let sortedShops = [];
  if(props.shops) {
    sortedShops = Object.entries(props.shops).sort((x, y) => x[1].createdAt < y[1].createdAt);
  }

  debug('Dashboard, stripeAccount:', props.stripeAccount);
  const showUpdateAccount = !!props.stripeAccount && props.stripeAccount.details_submitted;
  let intro = t('shop.accept_payments_intro');
  if(props.stripeAccount && props.stripeAccount.details_submitted) intro = t('shop.accept_payments_pending');
  if(props.stripeAccount && props.stripeAccount.charges_enabled) intro = t('shop.accept_payments_enabled');

  let hasFreeShops = true;
  let askEmail = false;
  if(props.profile) {
    hasFreeShops = (props.profile.paidShops > props.profile.nShops);
    askEmail = !props.profile.email;
  }

  return (
    <React.Fragment>
      <div className="feed bottombar">
        <h3>{t('shop.welcome')}</h3>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {t('shop.welcome_description')}
          &nbsp;
          <a href="mailto:hello@instashop.bio">hello@instashop.bio</a>
        </Typography>

        {askEmail && 
          <div className={classes.rowInput}>
            <TextField
              label={t('common.email')}
              name="email"
              className={classes.textfield} 
              helperText={t('shop.email_helper')}
              value={email}
              onChange={e => setEmail(e.target.value) }
              margin="normal"
              type="email"
            />

            <div className={classes.rowBtn}>
              <Button variant="contained" color="primary" onClick={ e => props.saveEmail(email) }>
                {t('buttons.save')}
              </Button>
            </div>
          </div>

          
        }


        <h3>{t('shop.accept_payments')}</h3>
        <Typography variant="body2" color="textSecondary">
          {intro}
        </Typography>
        <div>
          {props.stripeAccount ?
            <Button
              variant="contained"
              color="default"
              className={classes.addBtn}
              startIcon={<AccountBalanceIcon />}
              component={ButtonLink}
              to="/account_redirect"
              onClick={e => props.getAccountLink(props.stripeAccount.id, showUpdateAccount)}
            >
              {showUpdateAccount ? t('shop.update_bank_account') : t('shop.connect_bank_account')}
            </Button>
            : <Skeleton variant="rect" width={240} height={36} className={classes.addBtn} />}
        </div>

        {showUpdateAccount &&
          <Button
            variant="contained"
            color="default"
            className={classes.addBtn}
            startIcon={<TimelineIcon />}
            component={ButtonLink}
            to="/dashboard_redirect"
            onClick={e => props.getLoginLink(props.stripeAccount.id)}
          >
            {t('shop.payment_dashboard')}
          </Button>}


        <h3>{t('shop.shops')}</h3>
        <Typography variant="body2" color="textSecondary">
          {t('shop.shops_intro')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.addBtn}
          startIcon={<AddIcon />}
          component={ButtonLink}
          to={hasFreeShops ? "/shops/new" : "/shops/new/basic"}
        >
          {hasFreeShops ? t('shop.add_shop') : t('shop.buy_shop')}
        </Button>
        <List>
          {sortedShops.map( (x, i) => <ShopPreview key={`shop-${i}`} shop={x[1]} shopId={x[0]} /> )}
        </List>
      </div>

      <BottomNavBar location={props.location} />
    </React.Fragment>
  );

}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.isLoggedIn,
    isLoggingIn: state.isLoggingIn,
    profile: state.profile,
    shops: state.ownShops,
    stripeAccount: state.stripeAccount,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadDashboard: (title, description) => {
      dispatch(setPage("dashboard", title, description));
      dispatch(fetchOwnShops());
    },
    loadStripeAccount: () => dispatch(createStripeAccount()),
    getAccountLink: (accountId) => dispatch(getStripeAccountLink(accountId)),
    getLoginLink: (accountId) => dispatch(getStripeLoginLink(accountId)),
    saveEmail: email => {
      if(email) dispatch(updateProfile({ email: email }));
    },
  }
}

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(InnerDashboard);
export default Dashboard;


