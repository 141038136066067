import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CatalogProductPreview from './CatalogProductPreview';

const useStyles = makeStyles({
  catalog: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
      flexDirection: "row",
    }
  },
});

function Catalog(props) {
  const classes = useStyles();
  let products = null;
  if(props.products) {
    products = props.products
      .filter( x => x.published )
      .filter( x => !x.deleted )
      .map( (product, i) => <CatalogProductPreview key={`prod-${i}`} product = {product} /> );
  }
  return (
    <div className={classes.catalog}>
      {products}
    </div>
  );
}

export default Catalog;

