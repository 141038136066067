import React, {useEffect, useState} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';


const useStyles = makeStyles({
  container: {
    display: "flex",
    marginTop: 0,
  },
  quantity: {
    width: 36,
    marginLeft: 4,
    marginRight: 4,
  },
  quantityInput: {
    textAlign: "end",
  },
  button: {
    minWidth: 36,
    padding: "4px 0px",
  }
});


function ProductQuantity(props) {
  const [quantity, setQuantity] = useState(props.initial || 0);
  const classes = useStyles();

  const inc = () => {
    let q = quantity + 1;
    if(props.max && q > props.max) q = props.max;
    setQuantity(q);
    if(props.changeQuantity) props.changeQuantity(q);
  }

  const dec = () => {
    let q = Math.max(0, quantity - 1);
    setQuantity(q);
    if(props.changeQuantity) props.changeQuantity(q);
  }

  const change = (e) => {
    let q = Math.max(0, Number(e.target.value));
    if(props.max && q > props.max) q = props.max;
    setQuantity(q);
    if(props.changeQuantity) props.changeQuantity(q);
  }

  return (
    <div className={classes.container}>
      <div className={classes.decrement}>
        <Button variant="contained" size="small" color="default" className={classes.button}
            onClick={dec}>
          <RemoveIcon />
        </Button>
      </div>
      <div className={classes.quantity}>
        <Input className={classes.quantityInput} autoComplete="false" color="secondary" 
            name="quantity" type="number" 
            inputProps={{ style: { textAlign: "right" } }}
            onChange={change}
            value={quantity} />

      </div>
      <div className={classes.increment}>
        <Button variant="contained" size="small" color="default" className={classes.button} 
            onClick={inc}>
          <AddIcon />
        </Button>
      </div>

    </div>
  );

}

export default ProductQuantity;
