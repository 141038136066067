import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import { ButtonLink, debug, whatsappLink } from '../utils/utils';
import NumberFormat from 'react-number-format';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  container: {
    margin: "8px 0px 8px",
  },
}));

function OrderPreview(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');

  debug(`OrderPreview on order`, props.order);
  let name = props.order.id;
  let payment = null;
  let phone = props.order ? props.order.userPhone : null;
  let shopHandle = props.order && props.order.shop ? props.order.shop.handle : null;
  let shopUrl = shopHandle ?
    `https://instashop.bio/${shopHandle}` 
    : `https://instashop.bio/s/${props.order.shopId}`;

  debug(`Order created at `,props.order.createdAt);
  let secondary = (
    <span>
      {moment.unix(props.order.createdAt.seconds).format('lll')}
      &nbsp;
      {t(`order.${props.order.status}`)}
    </span>
  );
  if(props.order.payment) {
    if(props.order.payment.shipping) name = props.order.payment.shipping.name;

    payment = (
      <NumberFormat
        value={props.order.payment.amount_total/100.0}
        prefix={props.order.payment.currency.toUpperCase()}
        displayType={'text'}
        thousandSeparator={true} 
        decimalScale={2}
        fixedDecimalScale={true}
      />
    );
  }

  if(props.order.customer) {
    name = props.order.customer.name;
  }

  const primary = (
    <span>
      {name}
      &nbsp;
      {payment}
    </span>
  );

  return (
    <ListItem button divider disableGutters className={classes.container}
      component={ButtonLink}
      to={`/orders/${props.order.id}/fulfil`}
    >
      <ListItemText primary={primary} secondary={secondary}/>

      {phone &&
      <ListItemSecondaryAction>
        <Button aria-label="chat"
            color="inherit"
            href={whatsappLink(t('common.hello', { name: name }), shopUrl, phone)}
            startIcon={<WhatsAppIcon />}>

          {t('buttons.chat')}

        </Button>
      </ListItemSecondaryAction>}
      
    </ListItem>
  );
}

export default OrderPreview;
