import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { setPage, fetchOwnShops } from '../store/actions';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import OrderList from '../fulfilment/OrderList';
import BottomNavBar from '../nav/BottomNavBar';
import { ButtonLink, debug } from '../utils/utils';

const useStyles = makeStyles({
  addBtn: {
    margin: "16px 0px 0px",
  },
});

function Fulfilment(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');

  useEffect(() => {
    props.load(t('nav.fulfilment'));
  }, []);

  let sortedShops;
  let shopOrders;
  if(props.shops && props.shops.length>0) {
    sortedShops = Object.entries(props.shops).sort((x, y) => y[1].createdAt - x[1].createdAt);
    shopOrders = sortedShops.map( (x, i) => <OrderList key={`orders-shop-${i}`} shopId={x[0]} shop={x[1]} /> );
  }

  debug("Fulfilment page", sortedShops, shopOrders);

  return (
    <React.Fragment>
      <div className="feed bottombar">
        {shopOrders ||
          <div>
            <Typography variant="body2" color="textSecondary">
              {t('fulfilment.no_shops')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.addBtn}
              startIcon={<AddIcon />}
              component={ButtonLink}
              to={"/shops/new"}
            >
              {t('shop.add_shop')}
            </Button>
          </div>}
      </div>

      <BottomNavBar location={props.location} />
    </React.Fragment>

  );
}

const mapStateToProps = state => {
  return {
    shops: state.ownShops,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: (title, description) => {
      dispatch(setPage("fulfilment", title, description));
      dispatch(fetchOwnShops());
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Fulfilment);
