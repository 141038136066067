import React, { lazy, Suspense } from 'react';
import {
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { connect } from "react-redux";

import './App.css';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

import WebFont from 'webfontloader';
import ReactPixel from 'react-facebook-pixel';
import debug from 'debug';

import { CenteredCircularProgress } from './utils/utils';

import Login from './auth/Login';
import Logout from './auth/Logout';
import Landing from './home/Landing';
import Cart from './home/Cart';
import Pay from './home/Pay';
import Checkout from './checkout/Checkout';
import ViewOrder from './checkout/ViewOrder';
import Dashboard from './home/Dashboard';
import Fulfilment from './home/Fulfilment';
import Interstitial from './home/Interstitial';
import ViewShop from './shop/ViewShop';
import EditShop from './shop/EditShop';
import EditProduct from './shop/EditProduct';
import EditPaymentLink from './shop/EditPaymentLink';
import CatalogProductView from './shop/CatalogProductView';
import PaymentLinkView from './shop/PaymentLinkView';
import NavBar from './nav/NavBar';
import StatusMessage from './nav/StatusMessage';

const history = createBrowserHistory();

/* Loading fonts */
WebFont.load({
  google: {
    families: ['Lora:400']
  }
});

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: { main: '#c62828' },
    secondary: {main: '#3949ab' },
  },
});

const InnerPrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    if(rest.isLoggingIn === true) {
      debug('PrivateRoute: logging in');
      return (
        <CenteredCircularProgress />
      );
    }
    if(rest.isLoggedIn === true) {
      debug('PrivateRoute: logged in');
      return <Component {...props} />;
    }
    debug('PrivateRoute: redirecting to login');
    let loginUrl = `/login?next=${encodeURIComponent(window.location.href)}`;
    return <Redirect to={loginUrl} />;
  }} />
);


function InnerApp(props) {
  return (
    <Router history={history}>
      <div className="App">
        <ThemeProvider theme={theme}>
          <Suspense fallback={<CenteredCircularProgress />}>
            <NavBar history={history} />
            <Switch>
              <Route exact path="/" render={({history}) => <Landing history={history} />} />
              <Route exact path="/yourname" render={({history}) => <Landing history={history} />} />
              <Route path="/login" render={({history}) => <Login history={history} />} />
              <PrivateRoute path="/logout" component={Logout} />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PrivateRoute path="/fulfilment" component={Fulfilment} />
              <PrivateRoute exact path="/shops/:shopId/products/new" component={EditProduct} />
              <PrivateRoute path="/shops/:shopId/products/:productId/edit" component={EditProduct} />
              <PrivateRoute exact path="/shops/:shopId/payment_links/new" component={EditPaymentLink} />
              <PrivateRoute path="/shops/:shopId/payment_links/:paymentLinkId/edit" component={EditPaymentLink} />
              <PrivateRoute exact path="/shops/new/:plan" component={EditShop} />
              <PrivateRoute exact path="/shops/new" component={EditShop} />
              <PrivateRoute path="/shops/:shopId/edit" component={EditShop} />
              <PrivateRoute path="/shops/:shopId/pay/:landing" component={Pay} />
              <PrivateRoute path="/shops/:shopId/pay" component={Pay} />
              <PrivateRoute path="/orders/:orderId/:landing" component={ViewOrder} />
              <Route path="/products/:productId" render={({history, match}) => <CatalogProductView match={match} history={history} />} />
              <Route path="/payment_links/:paymentLinkId" render={({history, match}) => <PaymentLinkView match={match} history={history} />} />
              <Route path="/cart" render={({history, match}) => <Cart match={match} history={history} />} />
              <PrivateRoute path="/checkout" component={Checkout} />
              <Route path="/account_redirect"
                  render={ () => <Interstitial destination="Connect Bank Account" url={props.stripeAccountLink} /> } />

              <Route path="/dashboard_redirect"
                  render={ () => <Interstitial destination="Payment Dashboard" url={props.stripeLoginLink} /> } />
              <Route path="/s/:shopId/:tag" render={({history, match}) => <ViewShop match={match} history={history} />} />
              <Route path="/s/:shopId" render={({history, match}) => <ViewShop match={match} history={history} />} />
              <Route path="/:handle/:tag" render={({history, match}) => <ViewShop match={match} history={history} />} />
              <Route path="/:handle" render={({history, match}) => <ViewShop match={match} history={history} />} />
            </Switch>

            <StatusMessage />
          </Suspense>
        </ThemeProvider>
      </div>
    </Router>
  );
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.isLoggedIn,
    isLoggingIn: state.isLoggingIn,
    stripeAccountLink: state.stripeAccountLink,
    stripeLoginLink: state.stripeLoginLink,
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

const App = connect(mapStateToProps, mapDispatchToProps)(InnerApp);
const PrivateRoute = connect(mapStateToProps)(InnerPrivateRoute);

export default App;
