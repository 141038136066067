import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { debug } from '../utils/utils';

var db;

const firestore = {

  initialize: () => {
    db = firebase.firestore();
    if (window.location.hostname === "localhost") {
      debug(`Using firestore emulator on localhost`);
      db.useEmulator("localhost", 8080);
    } else {
      debug(`Using production firestore ${window.location.hostname}`);
    }
  },

  getUserId: () => {
    const auth = firebase.auth();
    let userId = null;
    if(auth && auth.currentUser) userId = auth.currentUser.uid;
    return userId;
  },

  getUserPhone: () => {
    const auth = firebase.auth();
    let phone = null;
    if(auth && auth.currentUser) phone = auth.currentUser.phoneNumber;
    return phone;
  },

  isHandleAvailable: async handle => {
    const normHandle = handle.trim().toLowerCase();
    const doc = await db.collection('handles').doc(normHandle).get();
    return !doc.exists;
  },

  createUser: obj => {
    return Object.assign({
      nShops: 0,
      paidShops: 1,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    }, obj);
  },

  fetchUser: async (userId=null) => {
    if(!userId) userId = firestore.getUserId();
    if(!userId) throw new Error("Can't fetch user, not logged in");
    const userRef = db.collection('users').doc(userId);
    const userDoc = await userRef.get();
    if(userDoc.exists) return { ...userDoc.data(), id: userDoc.id };
    const txn = await userRef.set(firestore.createUser({ userId: userId, phone: firestore.getUserPhone(), }));
    return userRef.get()
      .then(userDoc => {
        return { ...userDoc.data(), id: userDoc.id };
      });
  },

  updateUser: async (user) => {
    debug(`firestore.updateUser`);
    const userId = firestore.getUserId();
    if(!userId) throw new Error("Can't update user, not logged in");
    const userRef = db.collection('users').doc(userId);

    const txn = await userRef.set({
      ...user,
      userId: userId,
      phone: firestore.getUserPhone(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }, {merge: true});
    return userRef.get()
      .then(userDoc => {
        return { ...userDoc.data(), id: userDoc.id };
      });
  },

  validateHandle: handle => {
    const normHandle = handle.trim().toLowerCase();
    const pattern = /^[a-zA-Z0-9_]+$/;
    if(!pattern.test(normHandle)) throw new Error("Only letters, numbers and underscore _ allowed");
    if(normHandle.length<6 || normHandle.length>64) throw new Error("Handle should be 6 to 64 characters long");
    return normHandle;
  },

  createShop: shop => {
    const handle = shop.handle;
    debug(`firestore.createShop ${handle}`);
    const userId = firestore.getUserId();
    if(!userId) throw new Error("Can't save shop, not logged in");
    const normHandle = firestore.validateHandle(handle);

    const shopRef   = db.collection('shops').doc();
    const userRef   = db.collection('users').doc(userId);
    const handleRef = db.collection('handles').doc(normHandle);
    /*
    const planRef   = db.collection('plans')
      .where('userId', '==', userId)
      .where('status', '==', paid)
      .where('expires', '>', Date.now());
    const otherShopsRef = db.collection('shops').where('userId', '==', userId);
    const otherShops = await oherShopsRef.get();
    let nShops = 0;
    if(otherShops) {
      otherShops.forEach( doc => {
        let shopDoc = doc.data();
        if(!shopDoc.deleted) nShops += 1;
      });
    }
    debug(`createShop found ${nShops} active shops for user ${userId}`);
    */

    return db.runTransaction(async transaction => {
      const handleDoc = await transaction.get(handleRef);
      const userDoc   = await transaction.get(userRef);
      let plan;
      if(!handleDoc.exists) {
        debug(`firestore.createShop: ${handle} is available`);
        if(userDoc.exists) {
          const user = userDoc.data();
          if(!user.nShops || user.nShops==0) {
            debug(`firestore.saveHandle: creating first shop for free for user ${userId}`);
            plan = 'free';
            transaction.update(userRef, { nShops: 1 });
          } else {
            debug(`User ${userId} already has one shop`);
            debug(`firestore.saveHandle: incrementing shop count for user ${userId}`);
            plan = 'pending';
            transaction.update(userRef, { nShops: firebase.firestore.FieldValue.increment(1) });
          }
        } else {
          debug(`firestore.saveHandle: creating user ${userId}`);
          transaction.set(userRef, firestore.createUser({ userId: userId }));
        }

        const txn = await transaction
          .set(handleRef, {
            shopId: shopRef.id,
            handle: handle,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .set(shopRef, {
            ...shop,
            userId: userId,
            handleId: normHandle,
            handle: handle,
            published: true,
            banned: false,
            plan: plan,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        return txn;

      } else {
        debug(`firestore.createShop: ${handle} already taken`);
        throw new Error(`Handle ${handle} already taken`);
      }

    }).then( txn => {
      debug(`Saved handle ${handle}`);
      return shopRef.get();
    })
  },
  
  canEditShop: shop => {
    const userId = firestore.getUserId();
    if(userId === shop.userId) return true;
    return false;
  },

  updateShop: (shopId, shop, merge=false) => {
    if(merge) {
      const shopRef = db.collection('shops').doc(shopId);
      shopRef.set(shop, { merge: true });
      return shopRef.get();
    }
    const handle = shop.handle;
    debug(`firestore.updateShop ${handle}`);
    const userId = firestore.getUserId();
    if(!userId) throw new Error("Can't save shop, not logged in");
    const normHandle = firestore.validateHandle(handle);

    const shopRef = db.collection('shops').doc(shopId);
    const handleRef = db.collection('handles').doc(normHandle);
    let txn;

    return db.runTransaction(async transaction => {
      const handleDoc = await transaction.get(handleRef);
      const shopDoc   = await transaction.get(shopRef);
      if(!shopDoc.exists) throw new Error(`Can't update shop ${shopId}, not found`);
      if(!firestore.canEditShop(shopDoc.data())) throw new Error(`User not authorized to edit shop ${shopId}`);
      if(!handleDoc.exists) {
        debug(`firestore.updateShop: ${handle} is available`);
        const oldHandleRef = db.collection('handles').doc(shopDoc.data().handleId);

        txn = await transaction
          .set(handleRef, {
            shopId: shopRef.id,
            handle: handle,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .delete(oldHandleRef);

      } else {
        debug('handle shopId', handleDoc.shopId, 'shopRef.id', shopRef.id);
        if(handleDoc.data().shopId !== shopRef.id)
          throw new Error(`Handle ${handle} already taken`);
      }

      txn = await transaction.set(shopRef, {
            ...shop,
            userId: userId,
            handleId: normHandle,
            handle: handle,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          }, { merge: true });
      return txn;

    }).then( txn => {
      debug(`Updated shop ${handle}`);
      return shopRef.get();
    });
  },

  fetchOwnShops: () => {
    return new Promise(function(resolve, reject) {
      const userId = firestore.getUserId();
      if(!userId) throw new Error("Can't fetch own shops, not logged in");
      debug(`firestore.fetchOwnShops for user ${userId}`);
      const shopRef = db.collection('shops').where('userId', '==', userId);
      resolve(shopRef.get().then( results => {
        let shops = {};
        results.forEach( doc => {
          shops[doc.id] = { ...doc.data(), id: doc.id };
        });
        debug('firestore.fetchOwnShops returning', shops);

        return shops;

      }));
    });
  },

  fetchShopByHandle: (handle) => {
    debug(`firestore.fetchShopByHandle ${handle}`);
    const normHandle = firestore.validateHandle(handle);
    const shopRef = db.collection('shops').where('handleId', '==', normHandle);
    return shopRef.get().then( query => {
      if(!query.empty) {
        let doc = query.docs[0];
        return {...doc.data(), id: doc.id};
      }
      return null;
    });
  },

  fetchShopById: async shopId => {
    debug(`firestore.fetchShopById ${shopId}`);
    const shopDoc = await db.collection('shops').doc(shopId).get();
    if(shopDoc.exists) return {...shopDoc.data(), id: shopDoc.id};
    return null;
  },

  fetchShopOwner: async (shopId, shop) => {
    debug(`firestore.fetchShopOwner on ${shopId}, shop`, shop);
    if(!shop) shop = await firestore.fetchShopById(shopId);
    if(shop) {
      let owner = await firestore.fetchUser(shop.userId);
      if(owner) {
        return owner;
      } else {
        debug(`fetchShopOwner: Could not find owner ${shop.userId} of shop ${shopId}`);
      }
    } else {
      debug(`fetchShopOwner: Could not find shop ${shopId}`);
    }
    return null;
  },

  createProduct: async (shopId, product) => {
    debug(`firestore.createProduct`);
    const userId = firestore.getUserId();
    if(!userId) throw new Error("Can't save shop, not logged in");
    const productRef = db.collection('products').doc();
    const shopRef = db.collection('shops').doc(shopId);
    const shopDoc = await shopRef.get();
    if(!shopDoc.exists) throw new Error(`Can't add products to ${shopId}, not found`);
    if(!firestore.canEditShop(shopDoc.data()))
      throw new Error(`User not authorized to add products to shop ${shopId}`);

    let priceCents = Math.floor(product.price * 100.0);
    let slashedPriceCents = null;
    if(product.slashedPrice) slashedPriceCents = Math.floor(product.slashedPrice * 100.0);

    const txn = await productRef.set({
      ...product,
      priceCents,
      slashedPriceCents,
      userId: userId,
      shopId: shopId,
      published: true,
      rank: 0,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    return productRef.get();

  },

  updateProduct: async (productId, product, merge=false) => {
    debug(`firestore.updateProduct`);
    const productRef = db.collection('products').doc(productId);
    const oldProductDoc = await productRef.get();
    const userId = firestore.getUserId();
    if(!userId) throw new Error("Can't save shop, not logged in");
    const shopRef = db.collection('shops').doc(oldProductDoc.data().shopId);
    const shopDoc = await shopRef.get();
    if(!shopDoc.exists) throw new Error(`Can't edit product ${productId}, shop not found`);
    if(!firestore.canEditShop(shopDoc.data()))
      throw new Error(`User not authorized to add products to shop ${shopDoc.id}`);

    let priceCents = Math.floor(product.price * 100.0);
    let slashedPriceCents = null;
    if(product.slashedPrice) slashedPriceCents = Math.floor(product.slashedPrice * 100.0);

    /*
    if(merge) {
      productRef.set(product, { merge: true });
      return productRef.get();
    }*/

    const txn = await productRef.set({
      ...product,
      priceCents,
      slashedPriceCents,
      userId: userId,
      shopId: shopDoc.id,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }, {merge: true});
    return productRef.get();
  },

  fetchProducts: (productIds) => {
    debug(`firestore.fetchProducts by ids`, productIds);
    const productRefs = productIds.map( x => db.collection('products').doc(x) );
    return db.getAll(...productRefs).then( results => {
      let products = {};
      results.forEach( doc => {
        products[doc.id] = { ...doc.data(), id: doc.id };
      });

      return products;

    });
  },

  fetchProduct: productId => {
    debug(`firestore.fetchProduct ${productId}`);
    const productRef = db.collection('products').doc(productId);
    return productRef.get().then( doc => {
      return ({ ...doc.data(), id: doc.id });
    });
  },

  fetchShopProducts: (shopId, tag) => {
    debug(`firestore.fetchShopProducts for ${shopId} tag ${tag}`);
    let productRef = db.collection('products').where('shopId', '==', shopId);
    if(tag) productRef = productRef.where('tags', 'array-contains', tag);
    return productRef.get().then( results => {
      let products = {};
      results.forEach( doc => {
        products[doc.id] = { ...doc.data(), id: doc.id };
      });
      debug('firestore.fetchShopProducts returning', products);

      return products;

    });
  },

  fetchShopProductsByHandle: (handle, tag) => {
    return new Promise(function(resolve, reject) {
      debug(`firestore.fetchShopProductsByHandle for ${handle}, tag ${tag}`);
      resolve(firestore.fetchShopByHandle(handle).then(shop => {
        if(shop) { 
          return firestore.fetchShopProducts(shop.id, tag);
        }
      }));
    });
  },

  createPaymentLink: async (shopId, paymentLink) => {
    debug(`firestore.createPaymentLink`);
    const userId = firestore.getUserId();
    if(!userId) throw new Error("Can't save shop, not logged in");
    const paymentLinkRef = db.collection('paymentLinks').doc();
    const shopRef = db.collection('shops').doc(shopId);
    const shopDoc = await shopRef.get();
    if(!shopDoc.exists) throw new Error(`Can't add paymentLinks to ${shopId}, not found`);
    if(!firestore.canEditShop(shopDoc.data()))
      throw new Error(`User not authorized to add paymentLinks to shop ${shopId}`);

    let priceCents = Math.floor(paymentLink.price * 100.0);

    const txn = await paymentLinkRef.set({
      ...paymentLink,
      priceCents,
      userId: userId,
      shopId: shopId,
      published: true,
      rank: 0,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    return paymentLinkRef.get();

  },

  updatePaymentLink: async (paymentLinkId, paymentLink, merge=false) => {
    debug(`firestore.updatePaymentLink`);
    const paymentLinkRef = db.collection('paymentLinks').doc(paymentLinkId);
    const oldPaymentLinkDoc = await paymentLinkRef.get();
    const userId = firestore.getUserId();
    if(!userId) throw new Error("Can't save shop, not logged in");
    const shopRef = db.collection('shops').doc(oldPaymentLinkDoc.data().shopId);
    const shopDoc = await shopRef.get();
    if(!shopDoc.exists) throw new Error(`Can't edit paymentLink ${paymentLinkId}, shop not found`);
    if(!firestore.canEditShop(shopDoc.data()))
      throw new Error(`User not authorized to add paymentLinks to shop ${shopDoc.id}`);

    let priceCents = Math.floor(paymentLink.price * 100.0);

    if(merge) {
      paymentLinkRef.set(paymentLink, { merge: true });
      return paymentLinkRef.get();
    }

    const txn = await paymentLinkRef.set({
      ...paymentLink,
      priceCents,
      userId: userId,
      shopId: shopDoc.id,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    }, {merge: true});
    return paymentLinkRef.get();
  },

  fetchPaymentLink: paymentLinkId => {
    debug(`firestore.fetchPaymentLink ${paymentLinkId}`);
    const paymentLinkRef = db.collection('paymentLinks').doc(paymentLinkId);
    return paymentLinkRef.get().then( doc => {
      return ({ ...doc.data(), id: doc.id });
    });
  },

  fetchShopPaymentLinks: (shopId, tag) => {
    debug(`firestore.fetchShopPaymentLinks for ${shopId} tag ${tag}`);
    let paymentLinkRef = db.collection('paymentLinks').where('shopId', '==', shopId);
    if(tag) paymentLinkRef = paymentLinkRef.where('tags', 'array-contains', tag);
    return paymentLinkRef.get().then( results => {
      let paymentLinks = {};
      results.forEach( doc => {
        paymentLinks[doc.id] = { ...doc.data(), id: doc.id };
      });
      debug('firestore.fetchShopPaymentLinks returning', paymentLinks);

      return paymentLinks;

    });
  },

  fetchShopPaymentLinksByHandle: (handle, tag) => {
    return new Promise(function(resolve, reject) {
      debug(`firestore.fetchShopPaymentLinksByHandle for ${handle}, tag ${tag}`);
      resolve(firestore.fetchShopByHandle(handle).then(shop => {
        if(shop) { 
          return firestore.fetchShopPaymentLinks(shop.id, tag);
        }
      }));
    });
  },

  addToCart: productId => {
    return new Promise(function(resolve, reject) {
      debug(`firestore.addToCart ${productId}`);
      const userId = firestore.getUserId();
      if(!userId) throw new Error("Can't add to cart, not logged in");
      const productRef = db.collection('products').doc();
      resolve(productRef.get().then( product => {
        if(!product.exists) throw new Error(`Can't add product to card, ${productId} does not exist`);
        const cartRef = db.collection('carts').where('userId', '==', userId);
        cartRef.get().then(results => {
          let cartId = null;
          let cartQ = 0;
          results.forEach( doc => {
            if(doc.data().productId === productId) {
              cartId = doc.id;
              cartQ = doc.data().quantity;
            }
          });
          let cartRef;
          if(cartId) {
            cartRef = db.collection('carts').doc(cartId);
            cartRef.set({
              quantity: cartQ + 1,
              deleted: false,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            }, { merge: true });
          } else {
            cartRef = db.collection('carts').doc();
            cartRef.set({
              productId,
              shopId: product.data().shopId,
              name: product.data().name,
              currency: product.data().currency,
              price: product.data().price,
              userId,
              quantity: 1,
              deleted: false,
              purchased: false,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
          }
          return cartRef.get().then(cartDoc => ({...cartDoc.data(), id: cartDoc.id}));
        });
      }));

    });
  },

  createOrder: async (shopId, order) => {
    debug(`firestore.createOrder for shop ${shopId}`);
    const userId = firestore.getUserId();
    const orderRef = db.collection('orders').doc();
    const shopRef = db.collection('shops').doc(shopId);
    const shopDoc = await shopRef.get();
    if(!shopDoc.exists) throw new Error(`Can't create order to ${shopId}, not found`);
    const shop = shopDoc.data();

    const txn = await orderRef.set({
      products: order,
      userId: userId,
      userPhone: firestore.getUserPhone(),
      shopId: shopId,
      shop: {
        name: shop.name,
        handle: shop.handle,
      },
      placed: true,
      status: "pending",
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    return orderRef.get().then(orderDoc => ({...orderDoc.data(), id: orderDoc.id}));
  },

  fetchOrder: orderId => {
    debug(`firestore.fetchOrder ${orderId}`);
    // const userId = firestore.getUserId();
    const orderRef = db.collection('orders').doc(orderId);
    return orderRef.get().then(orderDoc => ({...orderDoc.data(), id: orderDoc.id}));
  },

  fetchFulfilmentOrders: shopId => {
    debug(`firestore.fetchFulfilmentOrders for shop ${shopId}`);
    const orderRef = db.collection('orders').where('shopId', '==', shopId);
    return orderRef.get().then( results => {
      let orders = {};
      results.forEach( doc => {
        orders[doc.id] = { ...doc.data(), id: doc.id };
      });
      debug('firestore.fetchFulfilmentOrders returning', orders);

      return orders;
    });

  },

  changeOrderStatus: (orderId, status, history) => {
    debug(`firestore.changeOrderStatus ${orderId} ${status}`);
    const userId = firestore.getUserId();
    const orderRef = db.collection('orders').doc(orderId);
    if(!history) history = [];
    history.push({
      userId: userId,
      status: status,
      timestamp: Math.floor(Date.now() / 1000),
    });
    return orderRef.update({
      status: status,
      history: history,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

  },

  createPlan: async (shopId, order) => {
    const userId = firestore.getUserId();
    debug(`firestore.createPlan on shop ${shopId} for user ${userId}`);
    const planRef = db.collection('plans').doc();

    const txn = await planRef.set({
      products: order,
      userId: userId,
      shopId: shopId,
      placed: true,
      status: "pending",
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    return planRef.get().then(planDoc => ({...planDoc.data(), id: planDoc.id}));
  },

  createGroupOrder: async (shopId, order, expiresAt=null) => {
    debug(`firestore.createGroupOrder for shop ${shopId}`);
    const userId = firestore.getUserId();
    const orderRef = db.collection('groupOrders').doc();
    const shopRef = db.collection('shops').doc(shopId);
    const shopDoc = await shopRef.get();
    if(!shopDoc.exists) throw new Error(`Can't create group order to ${shopId}, not found`);
    if(!expiresAt) {
      const tomorrow = new Date() + 1;
      expiresAt = tomorrow.setHours(23,59,59,0);
    }

    const txn = await orderRef.set({
      products: order,
      userId: userId,  // creator of the group
      users: firebase.firestore.FieldValue.arrayUnion(userId),
      nUsers: 1,
      shopId: shopId,
      placed: true,
      status: "pending",
      expiresAt: expiresAt,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    return orderRef.get().then(orderDoc => ({...orderDoc.data(), id: orderDoc.id}));
  },

  joinGroupOrder: async (orderId) => {
    debug(`firestore.joinGroupOrder ${orderId}`);
    const userId = firestore.getUserId();
    const orderRef = db.collection('groupOrders').doc(orderId);

    return orderRef.update({
      users: firebase.firestore.FieldValue.arrayUnion(userId),
      nUsers: firebase.firestore.FieldValue.increment(1),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

  },

}

export default firestore;
