import { Store, set, get, del } from 'idb-keyval';
import { debug } from '../utils/utils';

const customStore = new Store('instashop-db', 'instashop-store');

const localstore = {
  loadCart: () => {
    return get('cart', customStore);
  },

  saveCart: cart => {
    return set('cart', cart, customStore);
  },

  addToCart: (cart, productId, product, shop, quantity=1) => {
    if(!cart) cart = { shops: { [product.shopId]: { shop: {...shop}, products: {} } } };
    else {
      if(!cart.shops[product.shopId]) cart.shops[product.shopId] = { shop: {...shop}, products: {} };
    }
    if(cart.shops[product.shopId].products[productId]) {
      cart.shops[product.shopId].products[productId].quantity = quantity;
      cart.shops[product.shopId].products[productId].updatedAt = Date.now();
      if(quantity === 0) cart.shops[product.shopId].products[productId].deleted = true;
      cart.shops[product.shopId].products[productId].product = {...product};

    } else {
      cart.shops[product.shopId].products[productId] = {
        quantity: quantity,
        purchased: false,
        deleted: false,
        updatedAt: Date.now(),
        product: {...product, id: productId},
      }
    }
    cart.shops[product.shopId].updatedAt = Date.now();
    debug('localstore.addToCart returning cart', cart);
    return cart;
  },
/*
  addToCart: (productId, product, shop, quantity=1) => {
    return localstore.loadCart().then( cart => {
      if(!cart) cart = { shops: { [product.shopId]: { shop: {...shop}, products: {} } } };
      else {
        if(!cart.shops[product.shopId]) cart.shops[product.shopId] = { shop: {...shop}, products: {} };
      }
      if(cart.shops[product.shopId].products[productId]) {
        cart.shops[product.shopId].products[productId].quantity = quantity;
        cart.shops[product.shopId].products[productId].updatedAt = Date.now();
        if(quantity === 0) cart.shops[product.shopId].products[productId].deleted = true;
        cart.shops[product.shopId].products[productId].product = {...product};

      } else {
        cart.shops[product.shopId].products[productId] = {
          quantity: quantity,
          purchased: false,
          deleted: false,
          updatedAt: Date.now(),
          product: {...product, id: productId},
        }
      }
      cart.shops[product.shopId].updatedAt = Date.now();
      debug('localstore.addToCart setting cart to', cart);
      return set('cart', cart, customStore);
    });
  },
*/
  checkCartProduct: (shopId, productId, isChecked) => {
    return localstore.loadCart().then( cart => {
      if(cart && cart.shops) {
        if(cart.shops[shopId]) {
          if(cart.shops[shopId] && cart.shops[shopId].products && cart.shops[shopId].products[productId]) {
            cart.shops[shopId].products[productId].checked = isChecked;
          } else {
            debug(`No product ${productId} to check in cart`);
          }
        } else {
          debug(`No shop ${shopId} in cart to check product`);
        }
      } else {
        debug("Empty cart, can't check product");
      }
      debug('localstore.checkCartProduct setting cart to', cart);
      return set('cart', cart, customStore);
    });
  }
}

export default localstore;
