import React from 'react';
import { Link } from 'react-router-dom';

function ConditionalLink(props) {
  if(props.active) {
    return (
      <Link to={props.to} className={props.className}>
        {props.children}
      </Link>
    );
  }

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
}

export default ConditionalLink;
