import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ButtonLink } from '../utils/utils';

// list of home pages where back arrow navigation should not appear
const HOMEPAGES = ["homepage", "dashboard", "fulfilment", "shop"];
const NO_CART_PAGES = ["homepage", "dashboard", "fulfilment", "edit_product", "edit_shop", "plans"];

const useStyles = makeStyles({
  arrowBack: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  cartIcon: {
    padding: "12px 8px 8px"
  },
  topBtn: {
    marginTop: 8,
  },
});

function NavBar(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');
  const { isLoggedIn, isLoggingIn } = props;

  let loginBtn = null;
  let showLoginBtn = (props.nav==="homepage");

  if(!isLoggingIn) {
    if(isLoggedIn) {
      loginBtn = (
          <Button
            className={classes.topBtn}
            variant="contained"
            color="default"
            component={ButtonLink}
            to="/dashboard"
          >
            {t('buttons.sell')}
          </Button>
      );

    } else {
      loginBtn = (
          <Button
            className={classes.topBtn}
            variant="contained"
            color="default"
            component={ButtonLink}
            to="/dashboard"
          >
            {t('common.login')}
          </Button>
      );
    }
  }

  let backArrow = null;
  if(!props.nav || HOMEPAGES.includes(props.nav)) {
    // home page, no back arrow
  } else {
    backArrow = (
      <div>
        <IconButton onClick={ props.history.goBack } className={classes.arrowBack} color="inherit">
          <ArrowBackIcon />
        </IconButton>
      </div>
    );
  }

  let cartIcon = null;
  if(!props.nav || !NO_CART_PAGES.includes(props.nav)) {
    cartIcon = (
      <IconButton className={classes.cartIcon} color="inherit" component={ButtonLink} to="/cart">
        <Badge badgeContent={props.nCart} color="primary">
          <ShoppingCartIcon />
        </Badge>
      </IconButton>
    );
  }

  return (
    <nav className="navbar" id="nav">
      <div className="feed" style={{textAlign: "left", display: "flex", padding: "0px 8px" }}>
        {backArrow}
        <div className="logo">
          {/*
          <Link to="/" className="clean-link">
            <span className="logo">Instashop.bio</span>
          </Link>*/}
          {props.title}
        </div>
        <div style={{ marginLeft: "auto" }}>
          {showLoginBtn && loginBtn}
          {cartIcon}
        </div>
      </div>
    </nav>
  );
}


const mapStateToProps = (state, ownProps) => {
  let nCart = 0;
  if(state.cart) {
    if(state.cart.nShops===1) nCart = state.cart.nItems;
    else {
      if(state.shop && state.cart.shops[state.shop.id]) {
        nCart = Object.values(state.cart.shops[state.shop.id].products).filter(x => !x.deleted).length;
      }
    }
  }

  return {
    nav: state.nav,
    title: state.title,
    description: state.description,
    isLoggedIn: state.isLoggedIn,
    isLoggingIn: state.isLoggingIn,
    nCart,
  };
}

export default connect(mapStateToProps)(NavBar);
