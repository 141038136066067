import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { refreshShopLogoUrls } from '../store/actions';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';
import Chip from '@material-ui/core/Chip';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { ButtonLink } from '../utils/utils';

const useStyles = makeStyles(theme => ({
  container: {
    margin: "8px 0px 8px",
  },
  handle: {
    color: theme.palette.text.secondary,
    marginTop: 0,
  },
}));

function ShopPreview(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');

  useEffect(() => {
    if(props.shop && props.shop.logo && (!props.shop.logo.thumb || !props.shop.logo.mid || !props.shop.logo.max)) props.refresh(props.shop);
  }, []);

  let thumbUrl = null;
  if(props.shop && props.shop.logo) {
    thumbUrl = props.shop.logo.thumb || props.shop.logo.url;
  }

  let avatar = (
    <Avatar alt={props.shop.name} variant="rounded" 
      src={thumbUrl} 
    />
  );
  if(props.shop && props.shop.plan==='pending') {
    avatar = (
      <Avatar>
        <WarningIcon color="error" />
      </Avatar>
    );
  }

  let primary = props.shop.name;
  if(props.shop.plan==='pro') primary = (
    <span>
      {props.shop.name} <Chip label="PRO" variant="outlined" color="primary" />
    </span>
  );

  return (
    <ListItem button divider className={classes.container} component={ButtonLink} to={`/${props.shop.handle}`}>
      <ListItemAvatar>
        {avatar}
      </ListItemAvatar>
      <ListItemText primary={primary} secondary={props.shop.handle}/>

      <ListItemSecondaryAction>
        <IconButton aria-label="edit" 
          component={ButtonLink}
          to={`/shops/${props.shopId}/edit`}
        >
          <EditIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

const mapStateToProps = (state, ownProps) => {
  return { };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    refresh: (shop) => {
      dispatch(refreshShopLogoUrls(shop));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopPreview);
