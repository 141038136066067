import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation, Trans } from 'react-i18next';
import {
  setPage, fetchOwnShops,
  openStatusMessage, fetchStripeInstashopSession,
} from '../store/actions';
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import { Alert, AlertTitle } from '@material-ui/lab';

import { ButtonLink, getCurrency, STRIPE_PUBLIC_KEY, ADDITIONAL_SHOP_PRICE_ID, PRO_SHOP_PRICE_ID, debug } from '../utils/utils';
import NumberFormat from 'react-number-format';
import { loadStripe } from "@stripe/stripe-js";
import BottomButtons from '../nav/BottomButtons';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const useStyles = makeStyles({
  shopName: {
    margin: "8px 0px 0px",
  },
  emptyCart: {
    textAlign: "center",
    width: "100%",
    margin: "32px 8px",
  },
  orderContainer: {
    flexGrow: 1,
  },
  subtotalContainer: {
    fontSize: 12,
    flexGrow: 1,
  },
  subtotal: {
    color: "var(--mui-primary-color)",
  },
  message: {
    marginTop: 16,
    padding: 16,
  },
  continueBtn: {
    margin: "16px auto",
    padding: 16,
    textAlign: "center",
  },
  plan: {
    marginTop: 40,
  },
  support: {
    marginTop: 60,
    fontSize: 12,
    color: "var(--light-text-color)",
  }

});

function Pay(props) {
  const classes = useStyles();
  const [redirecting, setRedirecting] = useState(false);
  const [checkedPlan, setCheckedPlan] = useState('free');
  const { t, i18n } = useTranslation('app');

  useEffect(() => {
    if(props.shop) {
      if(props.shop.plan==='pending') setCheckedPlan('basic');
      else if(props.shop.plan==='basic') setCheckedPlan('pro');
      else setCheckedPlan('free');
    }
  }, [props.shop]);

  useEffect(() => {
    props.load(t('plans.plans'), props.shopId);
  }, [props.shopId, props.landing]);

  useEffect(() => {
    async function fetchStripeSession() {
      if(props.stripeSessionId) {
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
          sessionId: props.stripeSessionId,
        });
        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          debug('Could not redirect to Stripe', result.error);
          props.openStatusMessage(result.error.message);
        }
      }
    }
    fetchStripeSession();
  }, [props.stripeSessionId]);

  const stripeRedirect = () => {
    if(props.shopId) props.getSession(props.shopId, checkedPlan);
    debug('Redirecting to stripe checkout for Instashop purchase with id', props.stripeSessionId);
    setRedirecting(true);
  }

  let shops = [];
  let subtotalCents = 0;
  let term = null;
  if(checkedPlan==='basic') {
    subtotalCents = 900;
    term = <span className={classes.subtotal}>{t('plans.per_year')}</span>;
  } else if(checkedPlan==='pro') {
    subtotalCents = 1900;
    term = <span className={classes.subtotal}>{t('plans.per_month')}</span>;
  }
  let subtotal = (subtotalCents/100.0).toFixed(2);
  let currency = "USD";
  let plan1 = (
    <div className={classes.plan}>
      <Radio
        checked={checkedPlan==="basic"}
        disabled={!props.shop || props.shop.plan==='basic' || props.shop.plan==='free' || props.landing==='thankyou'}
        value="basic"
        onChange={ e => setCheckedPlan(e.target.value) }
      />
      <span onClick={ e => setCheckedPlan('basic')}>
        {t('plans.additional_shop_name')}: &nbsp;
        <b>{t('plans.additional_shop_price')}</b>
      </span>
      <Typography variant="body2" color="textSecondary" style={{ marginTop: 18, }}>
        {t('plans.additional_shop_description')}
      </Typography>
    </div>
  );
  if(props.shop && props.shop.plan==='free') {
    plan1 = (
      <div className={classes.plan}>
        <Radio
          checked={checkedPlan==="free"}
          disabled={!props.shop || props.shop.plan==='basic' || props.shop.plan==='free' || props.landing==='thankyou'}
          value="basic"
          onChange={ e => setCheckedPlan(e.target.value) }
        />
        <span onClick={ e => setCheckedPlan('free')}>
          {t('plans.free_shop_name')}: &nbsp;
          <b>{t('plans.free_shop_price')}</b>
        </span>
        <Typography variant="body2" color="textSecondary" style={{ marginTop: 18, }}>
          {t('plans.free_shop_description')}
        </Typography>
      </div>
    );
  }
  let plans = (
    <div>
      <Typography variant="body2" color="textSecondary">
        {props.shop 
          ? t('plans.plans_intro', { name: props.shop.name, plan: t(`plans.${props.shop.plan}`) }) 
          : <Skeleton variant="text" />}
      </Typography>

      {plan1}

      <div className={classes.plan}>
        <Radio
          disabled={!props.shop || props.shop.plan==='pro' || props.landing==='thankyou'}
          checked={checkedPlan==="pro"}
          value="pro"
          onChange={ e => setCheckedPlan(e.target.value) }
        />
        <span onClick={ e => setCheckedPlan('pro')}>
          {t('plans.pro_shop_name')}: &nbsp;
          <b>{t('plans.pro_shop_price')}</b>
        </span>
      </div>
      <Typography variant="body2" color="textSecondary" style={{ marginTop: 18, }}>
        {t('plans.pro_shop_description')}
      </Typography>
    </div>
  );
  let message = null;
  let continueBtn = null;
  if(props.landing === 'thankyou') {
    message = (
      <Alert severity="success">
        <AlertTitle>{t('plans.success')}</AlertTitle>
        {t('plans.success_description')}
      </Alert>
    );
    continueBtn = (
      <div className={classes.continueBtn}>
        <Button component={ButtonLink} to="/dashboard" variant="outlined" color="default" size="large">
          {t('plans.continue')}
        </Button>
      </div>
    );
  } else if (props.landing === 'cancel') {
    message = (
      <Alert severity="warning">
        <AlertTitle>{t('plans.cancel')}</AlertTitle>
        {t('plans.cancel_description')}
      </Alert>
    );
  }
  return (
    <div className="feed">
      {redirecting ?
        <div className={classes.emptyCart}>
          <Typography>{t('common.redirecting')}</Typography>
        </div>
        : plans
      }

      { message &&
        <div className={classes.message}>
          {message}
        </div>
      }

      { continueBtn }

      <div className={classes.support}>
        <Trans i18nkey="plans.contact_support" email="support@instashop.bio">
          Please contact us at <a href="mailto:support@instashop.bio">support@instashop.bio</a> for any question.
        </Trans>
      </div>

      { props.landing !== 'thankyou' &&
        <BottomButtons>
          <div className={classes.subtotalContainer}>
            {t('checkout.total')}:<br/>
            <NumberFormat
              value={subtotal}
              prefix={currency + " "}
              displayType={'text'}
              thousandSeparator={true} 
              className={classes.subtotal}
            />
            {term}
          </div>

          <div className={classes.orderContainer}>
            <Button fullWidth variant="contained" color="primary"
              disabled={redirecting}
              onClick={e => stripeRedirect()}
            >
              {t('buttons.place_order')}
            </Button>
          </div>

        </BottomButtons>
      }
    </div>
  );

}


const mapStateToProps = (state, ownProps) => {
  const shopId = (ownProps.match && ownProps.match.params) ? ownProps.match.params.shopId : null;
  const landing = (ownProps.match && ownProps.match.params) ? ownProps.match.params.landing : null;
  let shop = null;
  if(shopId && state.ownShops && state.ownShops[shopId]) shop = state.ownShops[shopId];
  debug('shopId', shopId, 'shop', shop);
  return {
    shopId,
    shop,
    landing,
    products: state.products,
    user: state.user,
    stripeSessionId: state.stripeSessionId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: (title) => {
      dispatch(setPage("plans", title));
      dispatch(fetchOwnShops());
    },
    openStatusMessage: message => dispatch(openStatusMessage(message)),
    getSession: (shopId, plan) => {
      debug(`Pay.getSession`);
      let order = [{
        quantity: 1,
        priceId: plan==='basic' ? ADDITIONAL_SHOP_PRICE_ID : PRO_SHOP_PRICE_ID,
      }];
      dispatch(fetchStripeInstashopSession(shopId, order, plan));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pay);
