import React from 'react';
import moment from 'moment';

function Footer(props) {
  return (
    <footer>
      <div>
        <p>&copy; Instashop.bio {moment().format('Y')} </p>
        <p>
          <a href="mailto:hello@instashop.bio">hello@instashop.bio</a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
