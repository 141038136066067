import { debug } from '../utils/utils';

const initialState = {
  nav: "homepage",
  title: "Instashop.bio",
  description: "Instashop.bio",
  isLoggedIn: false,
  isLoggingIn: true,
  openStatusMessage: false,
  statusMessage: "",
  user: null,
  session: {},
};

const rootReducer = (state = initialState, action) => {
  switch(action.type) {
    case "LOGGING_IN_EVENT":
      return {...state, isLoggingIn: true};

    case "LOGIN_EVENT":
      if(action.user) {
        return {...state, isLoggedIn: true, isLoggingIn: false, user: action.user};
      } else {
        return {...state, isLoggedIn: false, isLoggingIn: false, user: null};
      }

    case "RECEIVE_PROFILE":
      if(!action.profile) return state;
      return { ...state, profile: {...action.profile}};

    case "UPDATE_SESSION":
      return { ...state, session: {...state.session, ...action.session}};

    case "SET_PAGE":
      return { ...state, nav: action.nav, title: action.title, description: action.description };

    case "OPEN_STATUS_MESSAGE":
      return { ...state, openStatusMessage: true, statusMessage: action.message };

    case "CLOSE_STATUS_MESSAGE":
      return { ...state, openStatusMessage: false, statusMessage: null };

    case "RECEIVE_OWN_SHOPS":
      if(!action.shops) return state;
      return { ...state, ownShops: {...action.shops} };

    case "RECEIVE_SHOP":
      if(!action.shop) return { ...state, shop: null, shopNotFound: true };
      return { ...state, shop: {...action.shop}, shopNotFound: false };

    case "ADD_SHOP":
      if(!action.shop) return state;
      return { ...state, ownShops: Object.assign({...state.ownShops} || {}, action.shop) };

    case "RECEIVE_PRODUCTS":
      if(!action.products) return state;
      // return { ...state, products: Object.assign({...state.products} || {}, action.products) };
      return { ...state, products: { ...action.products } };

    case "RECEIVE_PAYMENT_LINKS":
      if(!action.paymentLinks) return state;
      return { ...state, paymentLinks: { ...action.paymentLinks } };

    case "RECEIVE_CART":
      debug("reducers.RECEIVE_CART", action.cart);
      if(!action.cart) return state;
      let nItems = 0;
      let nShops = 0;
      for(let shopId in action.cart.shops) {
        nItems += Object.values(action.cart.shops[shopId].products).filter(x => !x.deleted).length;
        nShops += 1;
      }
      debug("reducers.RECEIVE_CART with nItems", nItems);
      return { ...state, cart: { ...action.cart, nItems, nShops } };

    case "RECEIVE_STRIPE_SESSION":
      return { ...state, stripeSessionId: action.sessionId };

    case "RECEIVE_STRIPE_ACCOUNT":
      if(!action.account) return state;
      return { ...state, stripeAccount: {...action.account} };

    case "RECEIVE_STRIPE_ACCOUNT_LINK":
      if(!action.url) return state;
      return { ...state, stripeAccountLink: action.url };

    case "RECEIVE_STRIPE_LOGIN_LINK":
      if(!action.url) return state;
      return { ...state, stripeLoginLink: action.url };

    case "SELECT_TAB":
      return { ...state, selectedTab: action.tab };

    default:
      return state;
  }
};

export default rootReducer;
