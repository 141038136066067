import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { setPage, fetchCart, checkCartProduct, changeCartQuantity } from '../store/actions';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CartProduct from './CartProduct';
import BottomButtons from '../nav/BottomButtons';
import { ButtonLink, getCurrency, debug } from '../utils/utils';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles({
  cart: {
  },
  shopName: {
    margin: "8px 0px 0px",
  },
  emptyCart: {
    textAlign: "center",
    width: "100%",
    margin: "32px 8px",
  },
  checkoutContainer: {
    flexGrow: 1,
  },
  subtotalContainer: {
    fontSize: 12,
    flexGrow: 1,
  },
  subtotal: {
    color: "var(--mui-primary-color)",
  }
});

function Cart(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');

  useEffect(() => {
    props.load(t('cart.cart'), !props.cart);
  }, []);

  let shops = [];
  let subtotalCents = 0;
  let currency = getCurrency() || "USD";

  if(props.cart) {
    debug(`Showing cart with selected shop ${props.selectedShopId}`, props.cart);
    for(let shopId in props.cart.shops) {
      let products = null;
      if(shopId===props.selectedShopId) {
        debug(`Found selected shop ${shopId} in cart`);
        products = Object.values(props.cart.shops[shopId].products)
          .filter(x => !x.deleted)
          .map(
            (cartEntry, i) => {
              if(cartEntry.checked) subtotalCents += (cartEntry.product.priceCents || 0) * cartEntry.quantity;
              debug('Adding to subtotal, checked', cartEntry.checked,'cents: ', cartEntry.product.priceCents);
              return (
                <CartProduct 
                  key={`prod-${shopId}-${i}`} 
                  cartEntry = {cartEntry}
                  handleChange={e => props.checkCartProduct(shopId, cartEntry.product.id, e.target.checked)}
                  changeQuantity={q => props.changeCartQuantity(cartEntry.product.id, q)}
                />
              );
            }
          );
      }
      let shopName = `Shop ${shopId}`;
      let shopUrl  = null;
      if(props.cart.shops[shopId]) {
        shopName = props.cart.shops[shopId].shop.name;
        shopUrl  = `/${props.cart.shops[shopId].shop.handle}`;
      }
      if(products && products.length>0) {
        shops.push(
          <div className={classes.shop} key={`shop-${shopId}`}>
            <Link className="clean-link" to={shopUrl}>
              <h4 className={classes.shopName}>{shopName}</h4>
            </Link>
            <div>
              {products}
            </div>
          </div>
        );
      }
      
    }
  }
  let subtotal = (subtotalCents/100.0).toFixed(2);
  if(shops.length===0) {
    let redirect = "/";
    if(props.shop && props.shop.handle) redirect = `/${props.shop.handle}`;
    shops = (
      <div className={classes.emptyCart}>
        <Typography variant="body2" gutterBottom={true}>
          {t('cart.empty')}
        </Typography>

        <Button variant="outlined" component={ButtonLink} to={redirect}>
          {t('buttons.go_shopping')}
        </Button>
      </div>
    );
  }
  return (
    <div className="feed">
      {shops}

      <BottomButtons>
        <div className={classes.subtotalContainer}>
          {t('cart.subtotal')}:<br/>
          <NumberFormat
            value={subtotal}
            prefix={currency + " "}
            displayType={'text'}
            thousandSeparator={true} 
            className={classes.subtotal}
          />

        </div>

        <div className={classes.checkoutContainer}>
          <Button fullWidth variant="contained" color="primary"
            component={ButtonLink}
            to="/checkout"
          >
            {t('buttons.buy_now')}
          </Button>
        </div>

      </BottomButtons>
    </div>
  );

}

const mapStateToProps = (state, ownProps) => {
  let selectedShopId = null;
  debug("In cart, selected shop is", state.shop);
  if(state.shop) selectedShopId = state.shop.id;
  else {
    if(state.cart) {
      let latest = 0;
      for(let shopId in state.cart.shops) {
        if(!latest || state.cart.shops[shopId].updatedAt > latest) {
          latest = state.cart.shops[shopId].updatedAt;
          selectedShopId = shopId;
        }
      }
    }
  }
  return {
    cart: state.cart,
    shop: state.shop,
    selectedShopId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: (title, reload) => {
      dispatch(setPage("cart", title));
      if(reload) dispatch(fetchCart());
    },
    checkCartProduct: (shopId, productId, isChecked) => dispatch(checkCartProduct(shopId, productId, isChecked)),
    changeCartQuantity: (productId, quantity) => dispatch(changeCartQuantity(productId, quantity)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
