import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  setPage, fetchCart, clearCart,
  openStatusMessage, fetchStripeSession,
  fetchShopById, fetchShopOwner,
  fetchShopOwnerStripeAccountId,
} from '../store/actions';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CartProduct from '../home/CartProduct';
import BottomButtons from '../nav/BottomButtons';
import { ButtonLink, getCurrency, STRIPE_PUBLIC_KEY, debug } from '../utils/utils';
import NumberFormat from 'react-number-format';
import { loadStripe } from "@stripe/stripe-js";

// const stripePromise = loadStripe(STRIPE_PUBLIC_KEY, { stripeAccount: "acct_xxx" });
let stripePromise = null;

const useStyles = makeStyles({
  shopName: {
    margin: "8px 0px 0px",
  },
  emptyCart: {
    textAlign: "center",
    width: "100%",
    margin: "32px 8px",
  },
  orderContainer: {
    flexGrow: 1,
  },
  subtotalContainer: {
    fontSize: 12,
    flexGrow: 1,
  },
  subtotal: {
    color: "var(--mui-primary-color)",
  },
  fees: {
    color: "var(--mui-primary-color)",
  },
  feeEntry: {
    marginTop: 16,
  },
});

function Checkout(props) {
  const classes = useStyles();
  const [redirecting, setRedirecting] = useState(false);
  const { t, i18n } = useTranslation('app');

  useEffect(() => {
    props.load(t('checkout.checkout'), !props.cart, props.selectedShopId);
  }, [props.selectedShopId]);

  useEffect(() => {
    if(props.order && props.shop && !props.stripeSessionId)
      props.getSession(props.order, props.shop);

    if(props.shop && !stripePromise) {
      fetchShopOwnerStripeAccountId(props.shop)
        .then( stripeAccountId => {
          debug(`Loading stripe with account id ${stripeAccountId}`);
          stripePromise = loadStripe(STRIPE_PUBLIC_KEY, { stripeAccount: stripeAccountId });
        });
    }
  }, [props.shop]);

  const stripeRedirect = async () => {
    debug('Redirecting to stripe checkout with id', props.stripeSessionId);
    setRedirecting(true);
    props.clearCart(props.selectedShopId);
    const stripe = await stripePromise;
    const result = await stripe.redirectToCheckout({
      sessionId: props.stripeSessionId,
    });
    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      debug('Could not redirect to Stripe', result.error);
      props.openStatusMessage(result.error.message);
    }
  }

  let shops = [];
  let subtotalCents = 0;
  let currency = getCurrency() || "USD";
  if(props.order) {
    let shopId = props.selectedShopId;
    let products = Object.values(props.order.products)
      .filter(x => x.checked)
      .map(
        (cartEntry, i) => {
          currency = cartEntry.product.currency;
          if(cartEntry.checked) subtotalCents += (cartEntry.product.priceCents || 0) * cartEntry.quantity;
          return(
            <CartProduct 
              key={`prod-${shopId}-${i}`} 
              cartEntry = {cartEntry}
              checkout
            /> 
          )
        }
      );
    if(products && products.length>0) {
      let shopName = `Shop ${shopId}`;
      if(props.cart.shops[shopId]) {
        shopName = props.cart.shops[shopId].shop.name;
      }
      shops.push(
        <div className={classes.shop} key={`shop-${shopId}`}>
          <h4 className={classes.shopName}>{shopName}</h4>
          <div>
            {products}
          </div>
        </div>
      );
    }
    
  }
  let subtotal = (subtotalCents/100.0);
  let serviceFees = 0;
  let deliveryFees = 0;
  let addAmount = 0;

  let serviceFeesEntry = null;
  let deliveryFeesEntry = null;
  let addAmountEntry = null;

  if(props.shop) {
    if(props.shop.serviceFees>0) {
      serviceFees = (subtotalCents * props.shop.serviceFees / 10000.0);
      serviceFeesEntry = (
        <div className={classes.feeEntry}>
          <span>{t('checkout.service_fees')}</span>
          &nbsp;
          
          <NumberFormat
            value={serviceFees}
            prefix={currency + " "}
            displayType={'text'}
            thousandSeparator={true} 
            decimalScale={2}
            fixedDecimalScale={true}
            className={classes.fees}
          />
        </div>
      );
    }
    if(props.shop.deliveryFees>0) {
      if(!props.shop.deliveryThreshold || ((serviceFees+subtotal)<props.shop.deliveryThreshold)) {
        deliveryFees = props.shop.deliveryFees;
        addAmount = (props.shop.deliveryThreshold - serviceFees - subtotal);

        deliveryFeesEntry = (
          <div className={classes.feeEntry}>
            <span>{t('checkout.delivery_fees')}</span>
            &nbsp;
            
            <NumberFormat
              value={deliveryFees}
              prefix={currency + " "}
              displayType={'text'}
              thousandSeparator={true} 
              decimalScale={2}
              fixedDecimalScale={true}
              className={classes.fees}
            />
          </div>
        );

        addAmountEntry = (
          <div className={classes.feeEntry}>
            <span>{t('checkout.add_amount', { currency, amount: addAmount })}</span>
          </div>
        );
      }
    }
  }

  const total = subtotal + serviceFees + deliveryFees;

  if(shops.length===0) {
    shops = (
      <div className={classes.emptyCart}>
        <Typography variant="body2" gutterBottom={true}>
          {t('checkout.empty')}
        </Typography>

        <Button variant="outlined" component={ButtonLink} to="/cart">
          {t('buttons.go_cart')}
        </Button>
      </div>
    );
  }

  return (
    <div className="feed">
      {redirecting ?
        <div className={classes.emptyCart}>
          <Typography>{t('common.redirecting')}</Typography>
        </div>
        :
        <div>
          {shops}
          {serviceFeesEntry}
          {deliveryFeesEntry}
          {addAmountEntry}
        </div>
      }

      <BottomButtons>
        <div className={classes.subtotalContainer}>
          {t('checkout.total')}:<br/>
          <NumberFormat
            value={total}
            prefix={currency + " "}
            displayType={'text'}
            thousandSeparator={true} 
            className={classes.subtotal}
          />
        </div>

        <div className={classes.orderContainer}>
          <Button fullWidth variant="contained" color="primary"
            disabled={!props.stripeSessionId}
            onClick={e => stripeRedirect()}
          >
            {t('buttons.place_order')}
          </Button>
        </div>

      </BottomButtons>
    </div>
  );

}


const mapStateToProps = (state, ownProps) => {
  let selectedShopId = null;
  let shopOwnerId = null;
  let order = null;
  if(state.shop) {
    selectedShopId = state.shop.id;
    shopOwnerId = state.shop.userId;
  } else {
    if(state.cart) {
      let latest = 0;
      for(let shopId in state.cart.shops) {
        if(!latest || state.cart.shops[shopId].updatedAt > latest) {
          latest = state.cart.shops[shopId].updatedAt;
          selectedShopId = shopId;
        }
      }
    }
  }
  if(selectedShopId) order = state.cart.shops[selectedShopId];
  debug(`In checkout, selected shop is ${selectedShopId}`, state.shop);

  return {
    cart: state.cart,
    shop: state.shop,
    order: order,
    stripeSessionId: state.stripeSessionId,
    selectedShopId,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: (title, reload, shopId) => {
      dispatch(setPage("checkout", title));
      if(reload) dispatch(fetchCart());
      if(shopId) dispatch(fetchShopById(shopId));
    },
    openStatusMessage: message => dispatch(openStatusMessage(message)),
    getSession: (shopCart, shop) => {
      const shopId = shop.id;
      debug(`Checkout.getSession with shop cart shop ${shopId}`, shopCart);
      if(shopCart) {
        let order = [];
        for(let productId in shopCart.products) {
          let entry = shopCart.products[productId];
          if(entry.checked) {
            order.push({
              quantity: entry.quantity,
              product: entry.product,
            });
          }
        }
        dispatch(fetchStripeSession(order, shop));
      }
    },
    clearCart: (shopId) => {
      dispatch(clearCart(shopId));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
