import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Skeleton from '@material-ui/lab/Skeleton';
import { ButtonLink } from '../utils/utils';
import ConditionalLink from '../utils/ConditionalLink';
import NumberFormat from 'react-number-format';
import CartProductQuantity from './CartProductQuantity';

const useStyles = makeStyles(theme => ({
  container: {
    margin: "8px 0px 8px",
    display: "flex",
  },
  productName: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  productImageContainer: {
    width: 100,
    height: 100,
    marginRight: 8,
  },
  productImage: {
    width: "100%",
    height: "100%",
  },
  price: {
    margin: "8px 0px",
  },
  productPrice: {
    color: "var(--mui-primary-color)",
    marginTop: 4,
  },
  productSlashedPrice: {
    color: "var(--light-text-color)",
    textDecoration: "line-through",
    fontSize: 12,
    marginRight: 8,
  },
  unitsLeft: {
    marginTop: 8,
  }
}));

function CartProduct(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');

  let { 
    name = null, images = null, description = null, currency = null, price = null, slashedPrice = null,
    inventory = null
  } = props.cartEntry.product || {};
  let img = null;
  if(images && images.length>0) {
    let activeImages = images.filter( x => !x.deleted );
    if(activeImages.length>0) img = activeImages[0];
  }
  let showCheckbox = !props.checkout;
  let linkOut = !props.checkout;
  let allowChangeQuantity = !props.checkout;

  return (
    <div className={classes.container}>
      {showCheckbox &&
      <div className={classes.checkbox}>
        <Checkbox checked={props.cartEntry.checked || false} 
          color="primary" style={{paddingLeft: 0}}
          onChange={props.handleChange}
        />
      </div>}
      
      <div className={classes.productImageContainer}>
        <ConditionalLink className="clean-link" to={`/products/${props.cartEntry.product.id}`} active={linkOut}>
          {img && <img className={classes.productImage} src={img.thumb || img.url}/>}
        </ConditionalLink>

      </div>

      <div className={classes.description}>
        <ConditionalLink className="clean-link" to={`/products/${props.cartEntry.product.id}`} active={linkOut}>
          <div className={classes.productName}>
            {name}
          </div>

          <div className={classes.price}>

            <span className={classes.productPrice}>
              {price ? 
                <NumberFormat
                  value={price}
                  prefix={currency + " "}
                  displayType={'text'}
                  thousandSeparator={true} 
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
                : <Skeleton variant="text" width="100px" />
              }
            </span>

            {!allowChangeQuantity && 
              <span className={classes.fixedQuantity}>
                &nbsp; x{props.cartEntry.quantity}
              </span>
            }


          </div>
        </ConditionalLink>

        {allowChangeQuantity &&
          <CartProductQuantity 
            initial={props.cartEntry.quantity}
            max={inventory}
            changeQuantity={props.changeQuantity} 
          />
        }

        {inventory!==null &&
          <Typography variant="body2" color="textSecondary" className={classes.unitsLeft}>
            {t('checkout.only_left', { units: inventory})}
          </Typography>
        }
      </div>
    </div>
  );
}

export default CartProduct;
