import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LinkIcon from '@material-ui/icons/Link';
import { getForegroundColor, debug } from '../utils/utils';
import spotifyUri from 'spotify-uri';

const useStyles = makeStyles({
  container: {
    margin: "16px 0px",
  },
  buttonLink: {
    padding: "8px 16px",
    width: "100%",
    boxSizing: "border-box",
  },
  media: {
  },
});

function getDomainName(url) {
  if(!url) return null;
  try {
    if(!url.startsWith('http')) url = "https://" + url;
    const parse = new URL(url);
    const domain = parse.hostname;
    if(domain.startsWith('www.')) return domain.substring(4);
    return domain;
  } catch(err) {
    debug(`Could not parse url ${url}`, err);
    return null;
  }
}

function getYouTubeVideoId(url){
  var ID = '';
  url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if(url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  }
  else {
    ID = url;
  }
  debug(`Found youtube video ${ID}`);
  return ID;
}

function ViewLinks(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('app');

  const getNormalizedUrl = url => {
    if(!url) return null;
    if(!url.startsWith('http')) return "https://" + url;
    return url;
  };

  const getIcon = x => {
    let icon = <LinkIcon />;
    if(x && x.url) {
      const domain = getDomainName(x.url);
      if(domain.startsWith('instagram.com')) icon = <InstagramIcon />;
      else if(domain.startsWith('twitter.com')) icon = <TwitterIcon />;
      else if(domain.startsWith('linkedin.com')) icon = <LinkedInIcon />;
      else if(domain.startsWith('chat.whatsapp.com')) icon = <WhatsAppIcon />;
    }
    return icon;
  };

  const getMedia = x => {
    let media = null;
    let domain = getDomainName(x.url);
    if(!domain) return null;
    debug(`Getting media for domain ${domain}`);
    if(domain.startsWith('youtu.be') || domain.startsWith('youtube.')) {
      let videoId = getYouTubeVideoId(x.url);
      if(videoId) {
        media = (
          <iframe type="text/html" width="100%"
            frameBorder="0"
            src={`https://www.youtube.com/embed/${videoId}`}
          ></iframe>
        );
      }
    } else if(domain.startsWith('open.spotify.')) {
      let parsed = spotifyUri.parse(x.url);
      let uri = spotifyUri.formatEmbedURL(parsed);
      if(uri) {
        media = (
          <iframe src={uri} width="300" height="80" frameBorder="0" allowTransparency="true" allow="encrypted-media"></iframe>
        );
      }
    }
    return media;
  };

  if(!props.links) return null;

  let fg1 = getComputedStyle(document.querySelector(":root")).getPropertyValue("--text-color");
  let fg2 = getComputedStyle(document.querySelector(":root")).getPropertyValue("--dark-theme-text-color");
  let foregroundColor = props.backgroundColor ? getForegroundColor(props.backgroundColor, fg1, fg2) : fg1;


  return (
    <div className={classes.container}>
      {props.links.filter(x=>!x.deleted).map( (x, i) => {
        let media = getMedia(x);
        return (
          <div className={classes.buttonLink} key={`viewlink-${i}`}>
            {media ?
              <div className={classes.media} key={`viewlink-${i}-media`}>
                {media}
              </div>
              :
              <Button
                href={getNormalizedUrl(x.url)}
                variant="outlined"
                style={{ backgroundColor: props.backgroundColor, color: foregroundColor }}
                startIcon={getIcon(x)}
            fullWidth
              >
                {x.name}
              </Button>
            }
          </div>
        );
      })}
    </div>
  );


}

export default ViewLinks;
